import { Box, Grid, TextField, CircularProgress } from "@mui/material";
import { useNavigate } from "react-router-dom";
import HorizontalLinearStepper from "./Stepper";
import { useState, useEffect } from "react";
import server from "../../api/server";
import { useReqStore } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { NumericFormat } from "react-number-format";

const boxStyle1 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};

const ReqStage4 = () => {
  const navigate = useNavigate();

  const { req, setReq } = useReqStore();
  const [targetSubContract, setTargetSubContract] = useState({});
  const [firstSubContract, setFirstSubContract] = useState({});
  const token = localStorage.getItem("token");
  const reqId = localStorage.getItem("req-id");
  const [legal_representer, set_legal_representer] = useState({});

  const [loadingSettings, setLoadingSettings] = useState(true);
  const [settings, setSettings] = useState({});

  const [loading, setLoading] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingSave, setLoadingSave] = useState(false);

  const [districtCode, setDistrictCode] = useState(false);
  const [districtName, setDistrictName] = useState(false);
  const [cityName, setCityName] = useState(false);
  const [landNumber, setLandNumber] = useState(false);

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/broker-app/get-application-details/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      setLoading(false);
      const SubContract = data.data.contracts.sub_contracts.filter(
        (contract) => contract.type_id === 2
      );
      const otherSubContract = data.data.contracts.sub_contracts.filter(
        (contract) => contract.type_id === 1
      );
      setTargetSubContract(SubContract[0]);
      setFirstSubContract(otherSubContract[0]);
      set_legal_representer(data.data.contracts.legal_representer);
    } catch (error) {
      console.log(error);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await server.get(`/broker-app/settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSettings(data.data);
      setLoadingSettings(false);
    } catch (error) {
      console.log(error);
    }
  };

  const saveNationalAddress = async () => {
    setLoadingSave(true);
    try {
      const { data } = await server.post(
        `/broker-app/create-national-address`,
        {
          customer_id: req.renter.id,
          cityName,
          districtName,
          districtCode,
          landNumber,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoadingSave(false);
      getData();
    } catch (error) {
      setLoadingSave(false);
      console.log(error);
    }
  };
  const sendToNextstage = async () => {
    setLoadingNext(true);
    try {
      const { data } = await server.post(
        `/broker-app/update-sub-contract`,
        {
          status_id: 5,
          serial_no: targetSubContract.serial_no,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTargetSubContract(data.data);
      setLoadingNext(false);
      navigate(`/req/5`);
    } catch (error) {
      setLoadingNext(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
    getSettings();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className="box10"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box11" style={{ width: "100%" }}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                طلب وارد
              </div>
            </div>
          </div>
          <Box
            sx={{
              direction: "rtl",
              border: "1px solid var(--form-stroke, #E7E7E7)",
              padding: "10px",
              borderRadius: "8px",
              marginTop: 1,
              width: "auto",
              backgroundColor: "#fff",
            }}
          >
            <HorizontalLinearStepper acitve={3} />
          </Box>
          <Grid
            container
            spacing={1}
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={12} md={7}>
              <Box sx={boxStyle2}>
                <div className="" style={{ width: "100%" }}>
                  <div className="text8 center" style={{ marginRight: "0%" }}>
                    الطرف الأول
                  </div>
                </div>
                <div className="" style={{ width: "100%" }}>
                  <div
                    className="text23 center"
                    style={{
                      marginRight: "0%",
                      marginTop: 10,
                    }}
                  >
                    بيانات المؤجر الباطن
                  </div>
                </div>
                {loadingSettings ? (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    <CircularProgress />
                  </div>
                ) : (
                  <>
                    <Box sx={boxStyle1}>
                      <Grid container spacing={0}>
                        <Grid item xs={8}>
                          <div className="text3">اسم المؤسسة / المنشأة</div>
                          <div className="text8">
                            {settings.platformName || "لا يوجد"}
                          </div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="text3">رقم الجوال</div>
                          <div className="text8">{settings.phone}</div>
                        </Grid>
                      </Grid>
                    </Box>
                    <Box sx={boxStyle1}>
                      <Grid container spacing={0}>
                        <Grid item xs={8}>
                          <div className="text3">رقم السجل</div>
                          <div className="text8">{settings.license_no}</div>
                        </Grid>
                        <Grid item xs={4}>
                          <div className="text3">تاريخ الانتهاء</div>
                          <div className="text8">
                            {settings.license_expired_date}
                          </div>
                        </Grid>
                        {/* <Grid item xs={4}>
											<div className="text3">
												العنوان الوطني
											</div>
											<div className="text8">
												54324421
											</div>
										</Grid> */}
                      </Grid>
                    </Box>
                    <div className="" style={{ width: "100%" }}>
                      <div
                        className="text23 center"
                        style={{
                          marginRight: "0%",
                          marginTop: 10,
                        }}
                      >
                        العنوان الوطني
                      </div>
                    </div>
                    <Box sx={boxStyle1}>
                      <Grid container spacing={0}>
                        <Grid item xs={3}>
                          <div className="text3">المدينة</div>
                          <div className="text8">{settings.cityName}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text3">الرمز البريدي</div>
                          <div className="text8">{settings.districtCode}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text3">الحي</div>
                          <div className="text8">{settings.districtName}</div>
                        </Grid>
                        <Grid item xs={3}>
                          <div className="text3">رقم المبنى</div>
                          <div className="text8">{settings.landNumber}</div>
                        </Grid>
                      </Grid>
                    </Box>
                  </>
                )}
                {/*  */}
                <>
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23"
                      style={{
                        marginRight: "0%",
                        marginTop: "10px",
                      }}
                    >
                      بيانات ممثل الجهة
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className="text3">الاسم</div>
                        <div className="text8">
                          {legal_representer?.name || "لايوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">رقم الجوال</div>
                        <div className="text8">
                          {legal_representer?.phone || "لايوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className="text3">السجل المدني</div>
                        <div className="text8">
                          {legal_representer?.identity_number || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">العنوان الوطني</div>
                        <div className="text8">
                          {legal_representer?.national_address || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23 center"
                      style={{
                        marginRight: "0%",
                        marginTop: 10,
                      }}
                    >
                      العنوان الوطني
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <div className="text3">المدينة</div>
                        <div className="text8">
                          {legal_representer?.cityName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الرمز البريدي</div>
                        <div className="text8">
                          {legal_representer?.districtCode || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الحي</div>
                        <div className="text8">
                          {legal_representer?.districtName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">رقم المبنى</div>
                        <div className="text8">
                          {legal_representer?.landNumber || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23 center"
                      style={{
                        marginRight: "0%",
                        marginTop: 10,
                      }}
                    >
                      بيانات العقار
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      {/* <Grid item xs={4}>
                        <div className="text3">استخدام العقار</div>
                        <div className="text8">
                          {targetSubContract.building.building_use.name}
                        </div>
                      </Grid> */}
                      <Grid item xs={4}>
                        <div className="text3">نوع العقار</div>
                        <div className="text8">
                          {req.building_type?.name || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">نوع الوثيقة</div>
                        <div className="text8">
                          {targetSubContract.document_type?.name || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={4}>
                        <div className="text3">رقم الوثيقة</div>
                        <div className="text8">{firstSubContract.ejar_no}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">مدة العقد</div>
                        <div className="text8">
                          {targetSubContract.contract_period} شهر \ أشهر
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">رقم الايبان</div>
                        <div className="text8">
                          {targetSubContract.iban_no || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>

                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23 center"
                      style={{
                        marginRight: "0%",
                        marginTop: 10,
                      }}
                    >
                      العنوان الوطني
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <div className="text3">المدينة</div>
                        <div className="text8">{req.property_location}</div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الرمز البريدي</div>
                        <div className="text8">
                          {req.short_code || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الحي</div>
                        <div className="text8">
                          {req.property_neighbourhood || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">رقم المبنى</div>
                        <div className="text8">
                          {req.building_no || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              </Box>
              <Box sx={boxStyle2}>
                <div className="" style={{ width: "100%" }}>
                  <div className="text8 center" style={{ marginRight: "0%" }}>
                    بيانات المستأجر
                  </div>
                </div>
                <Box sx={boxStyle1}>
                  <Grid container spacing={0}>
                    <Grid item xs={4}>
                      <div className="text3">الاسم</div>
                      <div className="text8">{req.renter.name}</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="text3">رقم الجوال</div>
                      <div className="text8">{req.renter.phone}</div>
                    </Grid>
                    <Grid item xs={4}>
                      <div className="text3">رقم الهوية</div>
                      <div className="text8">
                        {req.renter.identity_number || "لا يوجد"}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
                <div className="" style={{ width: "100%" }}>
                  <div
                    className="text23 center"
                    style={{
                      marginRight: "0%",
                      marginTop: 10,
                    }}
                  >
                    العنوان الوطني
                  </div>
                </div>
                {req.renter.cityName && req.renter.districtCode ? (
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <div className="text3">المدينة</div>
                        <div className="text8">
                          {req.renter.cityName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الرمز البريدي</div>
                        <div className="text8">
                          {req.renter.districtCode || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الحي</div>
                        <div className="text8">
                          {req.renter.districtName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">رقم المبنى</div>
                        <div className="text8">
                          {req.renter.landNumber || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                ) : (
                  <Grid container spacing={1}>
                    <Grid item xs={3}>
                      <div className="text3">المدينة</div>
                      <TextField
                        onChange={(e) => setCityName(e.target.value)}
                        sx={{ mt: 0.5 }}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <div className="text3">الرمز البريدي</div>
                      <TextField
                        sx={{ mt: 0.5 }}
                        onChange={(e) => setDistrictCode(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <div className="text3">الحي</div>
                      <TextField
                        sx={{ mt: 0.5 }}
                        onChange={(e) => setDistrictName(e.target.value)}
                      />
                    </Grid>
                    <Grid item xs={3}>
                      <div className="text3">رقم المبنى</div>
                      <TextField
                        sx={{ mt: 0.5 }}
                        onChange={(e) => setLandNumber(e.target.value)}
                      />
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      sx={{
                        display: "flex",
                        justifyContent: "flex-end",
                      }}
                    >
                      <LoadingButton
                        loading={loadingSave}
                        variant="contained"
                        sx={{
                          backgroundColor: "#004A98",
                        }}
                        onClick={saveNationalAddress}
                      >
                        <div className="text3">حفظ</div>
                      </LoadingButton>
                    </Grid>
                  </Grid>
                )}
              </Box>
            </Grid>
            <Grid item xs={12} md={5}>
              <Box sx={boxStyle2}>
                <div className="" style={{ width: "100%" }}>
                  <div className="text8 center" style={{ marginRight: "0%" }}>
                    تفاصيل الطلب
                  </div>
                </div>

                <Grid
                  container
                  spacing={1}
                  direction="row"
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                >
                  <Grid item xs={6}>
                    <Box sx={boxStyle2}>
                      <div className="text3">مبلغ الإيجار</div>
                      <div
                        className="text6"
                        style={{
                          fontSize: "1.5rem",
                          color: "#014E97",
                          display: "flex",
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          value={req.final_rent_amount}
                        />
                        <div style={{ marginRight: 0 }}>ريال</div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={boxStyle2}>
                      <div className="text3">الضريبة</div>
                      <div
                        className="text6"
                        style={{
                          color: "#014E97",
                          display: "flex",
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          value={req.tax}
                        />
                        <div style={{ marginRight: 5 }}>ريال</div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={boxStyle2}>
                      <div className="text3">متوسط القسط الشهري</div>
                      <div
                        className="text8"
                        style={{
                          color: "#014E97",
                          marginTop: 5,
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          value={Math.round(req.total_rent_amount / 12)}
                        />
                        <div style={{ marginRight: 5 }}>ريال</div>
                      </div>
                    </Box>
                  </Grid>
                  <Grid item xs={6}>
                    <Box sx={boxStyle2}>
                      <div className="text3">المجموع</div>
                      <div
                        className="text6"
                        style={{
                          // fontSize: "1.5rem",
                          color: "#014E97",
                          display: "flex",
                        }}
                      >
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          value={req.total_rent_amount}
                          ريال
                        />
                        <div style={{ marginRight: 5 }}>ريال</div>
                      </div>
                    </Box>
                  </Grid>
                </Grid>
                <LoadingButton
                  loading={loadingNext}
                  disabled={!req.renter.landNumber}
                  variant="contained"
                  sx={{
                    backgroundColor: "#004A98",
                    width: "100%",
                    mt: 1,
                  }}
                  onClick={sendToNextstage}
                >
                  <div className="text3">التالي</div>
                </LoadingButton>
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </div>
  );
};

export default ReqStage4;
