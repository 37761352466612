import { Check } from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useReqStore } from "../../store";
import HorizontalLinearStepper from "./Stepper";
import Timer from "./Timer";

const ReqStage2 = () => {
  const { req, setReq } = useReqStore();
  const [loading, setLoading] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);
  const [loadingCancel, setLoadingCancel] = useState(false);
  const [custonerAproveLoading, setCustonerAproveLoading] = useState(false);
  const [qistarAproveLoading, setQistarAproveLoading] = useState(false);
  const [targetSubContract, setTargetSubContract] = useState(true);

  const navigate = useNavigate();
  const token = localStorage.getItem("token");
  const reqId = localStorage.getItem("req-id");

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/broker-app/get-application-details/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      setLoading(false);
      const SubContract = data.data.contracts.sub_contracts.filter(
        (contract) => contract.type_id === 2
      );
      setTargetSubContract(SubContract[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const disableNextButton = () => {
    if (targetSubContract?.is_qistar_ejar_approved === 0) {
      return true;
    } else if (targetSubContract?.is_cusotmer_ejar_approved === 0) {
      return true;
    } else {
      return false;
    }
  };

  //send onwer aproved the contract
  const senCustomerAproval = async () => {
    setCustonerAproveLoading(true);
    try {
      const { data } = await server.post(
        `/broker-app/update-sub-contract`,
        {
          serial_no: targetSubContract.serial_no,
          is_cusotmer_ejar_approved: 1,
          status_id: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTargetSubContract(data.data);
      setCustonerAproveLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const sendQistarAproval = async () => {
    setQistarAproveLoading(true);
    try {
      const { data } = await server.post(
        `/broker-app/update-sub-contract`,
        {
          serial_no: targetSubContract.serial_no,
          is_qistar_ejar_approved: 1,
          status_id: 2,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTargetSubContract(data.data);
      setQistarAproveLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  const cancelApp = async () => {
    setLoadingCancel(true);
    try {
      const { data } = await server.post(
        `/broker-app/cancel-application`,
        {
          serial_number: req.serial_number,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/req");
    } catch (error) {
      setLoadingCancel(false);
      console.log(error);
    }
  };

  const sendToNextstage = async () => {
    setLoadingNext(true);
    try {
      const { data } = await server.post(
        `/broker-app/update-sub-contract`,
        {
          serial_no: targetSubContract.serial_no,
          status_id: 6,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTargetSubContract(data.data);
      setLoadingNext(false);
      navigate(`/req/6`);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className="box10"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box11" style={{ width: "100%" }}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                طلب وارد
              </div>
            </div>
          </div>
          <Box
            sx={{
              direction: "rtl",
              border: "1px solid var(--form-stroke, #E7E7E7)",
              padding: "10px",
              borderRadius: "8px",
              marginTop: 1,
              width: "auto",
              backgroundColor: "#fff",
            }}
          >
            <HorizontalLinearStepper acitve={4} />
          </Box>
          <Grid
            container
            spacing={1}
            direction="row-reverse"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle2}>
                <div className="" style={{ width: "100%" }}>
                  <div className="text8 center" style={{ marginRight: "0%" }}>
                    الطرف الأول
                  </div>
                </div>
                <div className="" style={{ width: "100%" }}>
                  <div
                    className="text23 center"
                    style={{
                      marginRight: "0%",
                      marginTop: 10,
                    }}
                  >
                    قسطار
                  </div>
                </div>
                <Box sx={boxStyle1}>
                  <div>
                    <div className="text3">الإسم</div>
                    <div className="text8">قسطار</div>
                  </div>
                  <div>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">54324421</div>
                  </div>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <LoadingButton
                    loading={qistarAproveLoading}
                    disabled={targetSubContract?.is_qistar_ejar_approved === 1}
                    variant="contained"
                    sx={{
                      bgcolor: "#27AE60",
                    }}
                    onClick={sendQistarAproval}
                  >
                    <Check />
                    <div className="text3">تمت الموافقة</div>
                  </LoadingButton>
                  <div style={{ display: "flex" }}>
                    <div className="text8">أو</div>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "#004A98",
                        mr: "10px",
                      }}
                    >
                      <div className="text3">إرسال رسالة تذكيرية</div>
                    </Button>
                  </div>
                </div>
              </Box>
            </Grid>
            <Grid item xs={12} md={6}>
              <Box sx={boxStyle2}>
                <div className="" style={{ width: "100%" }}>
                  <div className="text8 center" style={{ marginRight: "0%" }}>
                    الطرف الثاني
                  </div>
                </div>
                <div className="" style={{ width: "100%" }}>
                  <div
                    className="text23 center"
                    style={{
                      marginRight: "0%",
                      marginTop: 10,
                    }}
                  >
                    المستأجر
                  </div>
                </div>
                <Box sx={boxStyle1}>
                  <div>
                    <div className="text3">الإسم</div>
                    <div className="text8">{req.renter.name}</div>
                  </div>
                  <div>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">{req.owner.phone}</div>
                  </div>
                </Box>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: 10,
                  }}
                >
                  <LoadingButton
                    loading={custonerAproveLoading}
                    disabled={
                      targetSubContract?.is_cusotmer_ejar_approved === 1
                    }
                    variant="contained"
                    sx={{
                      bgcolor: "#27AE60",
                    }}
                    onClick={senCustomerAproval}
                  >
                    <Check />
                    <div className="text3">تمت الموافقة</div>
                  </LoadingButton>
                  <div style={{ display: "flex" }}>
                    <div className="text8">أو</div>
                    <Button
                      variant="contained"
                      sx={{
                        bgcolor: "#004A98",
                        mr: "10px",
                      }}
                    >
                      <div className="text3">إرسال رسالة تذكيرية</div>
                    </Button>
                  </div>
                </div>
              </Box>
            </Grid>
          </Grid>
          {/* <Box sx={boxStyle2}>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: 10,
							}}
						>
							<div>
								<p className="text8">
									الوقت المتبقي لإكمال الطلب
								</p>
								<p className="text11">
									يجب إكمال العقد خلال الفترة المحددة
								</p>
							</div>
							<Timer expiresIn={100000000} />
						</div>
					</Box> */}
          <div
            style={{
              display: "flex",
              flexDirection: "row-reverse",
              gap: 10,
              justifyContent: "flex-end",
              marginTop: 10,
            }}
          >
            {/* <LoadingButton
							loading={loadingCancel}
							onClick={cancelApp}
							variant="contained"
							color="error"
						>
							<div className="text3">إلغاء طلب التعاقد</div>
						</LoadingButton> */}
            <LoadingButton
              loading={loadingNext}
              disabled={disableNextButton()}
              variant="contained"
              sx={{ backgroundColor: "#004A98" }}
              onClick={sendToNextstage}
            >
              <div className="text3">التالي</div>
            </LoadingButton>
          </div>
        </>
      )}
    </div>
  );
};

export default ReqStage2;

const boxStyle1 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};
