import React, { useState } from "react";
// react component that copies the given text inside your clipboard
import { CopyToClipboard } from "react-copy-to-clipboard";
// @material-ui/core components

// core components
import { Box, Grid, Tooltip } from "@mui/material";
import { CopyAll } from "@mui/icons-material";

const ReferralCode = ({ code }) => {
  const [copiedText, setCopiedText] = useState();
  return (
    <>
      <Grid
        item
        md={3}
        xs={12}
        component={Box}
        paddingLeft="15px"
        paddingRight="15px"
      >
        <CopyToClipboard text={code} onCopy={() => setCopiedText(code)}>
          <Tooltip
            title={copiedText === code ? "تم النسخ" : "نسخ"}
            placement="top"
          >
            <Box
              component="button"
              fontFamily="inherit"
              fontSize="16px"
              fontWeight="400"
              lineHeight="1.25"
              display="inline-block"
              width="100%"
              margin=".5rem 0"
              padding="10px"
              textAlign="right"
              //   color={theme.palette.gray[800]}
              border="0"
              borderRadius="4px"
              //   className={classes.button}
              data-clipboard-text="album-2"
              type="button"
              sx={{ border: "1px solid #E7E7E7", background: "#fff" }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  fontFamily: "font-med",
                  alignItems: "center",
                }}
              >
                <span>نسخ رابط الإحالة</span>
                <CopyAll />
              </div>
            </Box>
          </Tooltip>
        </CopyToClipboard>
      </Grid>
    </>
  );
};

export default ReferralCode;
