import {
  Box,
  Grid,
  TextField,
  IconButton,
  styled,
  Button,
  CircularProgress,
} from "@mui/material";
import HorizontalLinearStepper from "./Stepper";
import icon1 from "../../assets/upload.png";
import { useNavigate } from "react-router-dom";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { useState, useEffect } from "react";
import server from "../../api/server";
import { useReqStore } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import dayjs from "dayjs";
import AlertMessage from "../custom/AlertMessage";

const handleDate = (value) => {
  if (value.date() === 1 && value.month() === 0) {
    const Day = value.set("date", value.date() - 1);
    return Day;
  } else {
    const Day = value.set("date", value.date() - 1);
    return Day.set("year", value.year() + 1);
  }
};

const ReqStage6 = () => {
  const navigate = useNavigate();
  const { req, setReq } = useReqStore();
  const [ownerSubContract, setOwnerSubContract] = useState({});
  const [targetSubContract, setTargetSubContract] = useState({});
  const token = localStorage.getItem("token");
  const reqId = localStorage.getItem("req-id");

  const [loading, setLoading] = useState(true);
  const [loadingNext, setLoadingNext] = useState(false);

  const [start_date, set_start_date] = useState(dayjs(new Date()));
  const [end_date, set_end_date] = useState(handleDate(start_date));
  const [ejar_no, set_ejar_no] = useState("");
  const [ejar_file, set_ejar_file] = useState(null);

  const [errorMessgae, setErrorMessgae] = useState("");
  const [openError, setOpenError] = useState(false);

  const formData = new FormData();
  formData.append("start_date", ownerSubContract?.start_date);
  formData.append("end_date", ownerSubContract?.end_date);
  formData.append("ejar_no", ejar_no);
  formData.append("ejar_file", ejar_file);
  formData.append("status_id", 7);
  formData.append("serial_no", targetSubContract.serial_no);

  //vaildations
  const [errorList, setErrorList] = useState({});
  const [fileError, setFileError] = useState(false);
  const [serverError, setServerError] = useState({});
  const feilds = {
    start_date,
    end_date,
    ejar_no,
    ejar_file,
  };
  const validate = (value) => {
    if (!value || value === "") {
      return true;
    } else {
      return false;
    }
  };
  const validateObject = () => {
    const results = {};
    for (const [key, value] of Object.entries(feilds)) {
      results[key] = validate(value);
    }
    setErrorList(results);
  };
  const ErrorText = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        لايمكنك ترك هذا الحقل فارغاً
      </div>
    );
  };
  const FileErrorText = () => {
    return (
      <div
        className="text2"
        style={{ color: "red", marginTop: "0px", width: "100%" }}
      >
        {fileError}
      </div>
    );
  };
  const ServerErrorText = ({ value }) => {
    if (serverError[value]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[value]}
        </div>
      );
    }
    return;
  };
  const validateFile = () => {
    if (ejar_file) {
      console.log(ejar_file.size);
      if (ejar_file.size > 2097152) {
        setFileError(true);
      }
      const fname = ejar_file.name;
      const re = /(\.pdf)$/i;
      if (!re.exec(fname)) {
        set_ejar_file(null);
        setFileError(true);
        return;
      }
    }
  };

  const handleSetDate = (value) => {
    set_start_date(value);
    set_end_date(handleDate(value));
  };

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/broker-app/get-application-details/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      setLoading(false);
      const SubContract = data.data.contracts.sub_contracts.filter(
        (contract) => contract.type_id === 2
      );
      const OwnerSubContract = data.data.contracts.sub_contracts.filter(
        (contract) => contract.type_id === 1
      );
      setOwnerSubContract(OwnerSubContract[0]);
      setTargetSubContract(SubContract[0]);
    } catch (error) {
      console.log(error);
    }
  };

  const sendToNextstage = async () => {
    if (!start_date || !end_date || !ejar_no || !ejar_file || fileError) return;
    if (ejar_file.size > 2097152) {
      setFileError(" حجم الملف اكبر من 2 ميجابايت");
      return;
    }
    const fname = ejar_file.name;
    const re = /(\.pdf)$/i;
    if (!re.exec(fname)) {
      set_ejar_file(null);
      setFileError("يجب ان يكون الملف بصيغة pdf");
      return;
    }
    setLoadingNext(true);
    try {
      const { data } = await server.post(
        `/broker-app/update-sub-contract`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setTargetSubContract(data.data);
      setLoadingNext(false);
      navigate(`/req`);
    } catch (error) {
      setLoadingNext(false);
      if (error.response.status === 422) {
        setServerError(error.response.data.error);
      } else if (error.response.status === 500) {
        setErrorMessgae(error.response?.data?.message);
        setOpenError(true);
      }
      console.log(error);
    }
  };

  const handleNext = () => {
    setErrorList({});
    setFileError(false);
    validateObject();
    validateFile();
    sendToNextstage();
  };

  useEffect(() => {
    getData();
  }, []);

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  return (
    <div style={{ width: "100%" }}>
      {loading ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <>
          <div
            className="box10"
            style={{
              display: "flex",
              justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box11" style={{ width: "100%" }}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                طلب وارد
              </div>
            </div>
          </div>
          <Box
            sx={{
              direction: "rtl",
              border: "1px solid var(--form-stroke, #E7E7E7)",
              padding: "10px",
              borderRadius: "8px",
              marginTop: 1,
              width: "auto",
              backgroundColor: "#fff",
            }}
          >
            <HorizontalLinearStepper acitve={5} />
          </Box>
          <Box sx={boxStyle2}>
            <div className="" style={{ width: "100%" }}>
              <div className="text8 center" style={{ marginRight: "0%" }}>
                إرسال تفاصيل التعاقد للمستأجر
              </div>
            </div>
            <div className="" style={{ width: "100%" }}>
              <div
                className="text23 center"
                style={{
                  marginRight: "0%",
                  marginTop: 10,
                }}
              >
                العقد بين قسطار والمستأجر
              </div>
            </div>
            <div
              className="text3 mr0"
              style={{
                marginBottom: 5,
                width: "auto",
                marginTop: 10,
              }}
            >
              رقم العقد على منصة إيجار
            </div>
            <TextField
              fullWidth
              type="number"
              onChange={(e) => set_ejar_no(e.target.value)}
            />
            {errorList.ejar_no && <ErrorText />}
            <ServerErrorText value={"ejar_no"} />
            <Grid
              container
              spacing={1}
              direction="row"
              justifyContent="flex-start"
              alignItems="flex-start"
              alignContent="stretch"
              wrap="wrap"
            >
              <Grid item xs={12} md={6}>
                <div
                  className="text3 mr0"
                  style={{
                    marginBottom: 5,
                    width: "auto",
                    marginTop: 10,
                  }}
                >
                  تاريخ بداية العقد
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MobileDatePicker"]}>
                    <MobileDatePicker
                      disabled
                      sx={{
                        backgroundColor: "#F1F4FF",
                        width: "100%",
                        // direction: "rtl",
                        color: "GrayText",
                        fontFamily: "font",
                        padding: 0,
                      }}
                      size="small"
                      className="textInput1"
                      variant="outlined"
                      format="DD-MM-YYYY"
                      // onChange={(newValue) =>
                      // 	handleSetDate(newValue)
                      // }
                      value={dayjs(ownerSubContract.start_date)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {errorList.start_date && <ErrorText />}
              </Grid>
              <Grid item xs={12} md={6}>
                <div
                  className="text3 mr0"
                  style={{
                    marginBottom: 5,
                    width: "auto",
                    marginTop: 10,
                  }}
                >
                  تاريخ نهاية العقد
                </div>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DemoContainer components={["MobileDatePicker"]}>
                    <MobileDatePicker
                      disabled
                      sx={{
                        backgroundColor: "#F1F4FF",
                        width: "100%",
                        // direction: "rtl",
                        color: "GrayText",
                        fontFamily: "font",
                        padding: 0,
                      }}
                      size="small"
                      className="textInput1"
                      variant="outlined"
                      format="DD-MM-YYYY"
                      value={dayjs(ownerSubContract.end_date)}
                    />
                  </DemoContainer>
                </LocalizationProvider>
                {errorList.end_date && <ErrorText />}
              </Grid>
            </Grid>
            <Box sx={boxStyle2}>
              <div
                className="text10 mr0"
                style={{ alignItems: "center", gap: 10 }}
              >
                إرفاق ملف العقد
                <div className="text2">
                  {"(يجب أن لا يزيد حجم الملف عن 2 ميجابايت)"}
                </div>
              </div>
              <div className="box12" style={{ marginTop: 10 }}>
                <IconButton component="label">
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => set_ejar_file(e.target.files[0])}
                    accept=".pdf"
                  />
                  <div className="box13">
                    <img src={icon1} alt="icon" />
                  </div>
                </IconButton>
                {ejar_file ? (
                  <div
                    className="text2 t201 bold"
                    style={{ textAlign: "center" }}
                  >
                    {ejar_file.name}
                  </div>
                ) : (
                  <div className="text2 t201 bold">
                    قم بإرفاق نسخة العقد بصيغة PDF من منصة إيجار
                  </div>
                )}
              </div>
              {errorList.ejar_file && <ErrorText />}
              {fileError && <FileErrorText />}
            </Box>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 10,
                justifyContent: "flex-end",
                marginTop: 10,
              }}
            >
              <LoadingButton
                loading={loadingNext}
                variant="contained"
                sx={{ backgroundColor: "#004A98" }}
                onClick={handleNext}
              >
                <div className="text3">إرسال معلومات التعاقد الى المستأجر</div>
              </LoadingButton>
            </div>
          </Box>
        </>
      )}
      <AlertMessage
        message={errorMessgae}
        open={openError}
        setOpen={setErrorMessgae}
        type={"error"}
      />
    </div>
  );
};

export default ReqStage6;

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};
