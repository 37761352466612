import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useReqStore } from "../../store";
import { useState } from "react";
import { LoadingButton } from "@mui/lab";
import { Box, TextField } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

export default function CashOut({ open, setOpen, data, reload }) {
  const [loadingSendOffer, setLoadingSendOffer] = useState(false);
  const [serverError, setServerError] = useState({});
  const { amount, id } = data;
  const user = JSON.parse(localStorage.getItem("user"));

  const navigate = useNavigate();
  const { setReq } = useReqStore();
  const token = localStorage.getItem("token");

  const ServerErrorText = ({ value }) => {
    if (serverError[value]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {serverError[value]}
        </div>
      );
    }
  };

  const renderUpdateProfile = () => {
    if (!user.iban || !user.account_owner_name) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          الرجاء تحديث بيانات الملف الشخصي قبل انشاء طلب صرف
        </div>
      );
    }
  };
  const renderUpdateProfilePage = () => {
    if (!user.iban || !user.account_owner_name) {
      return (
        <div
          className="text2"
          style={{
            color: "#1f1f1f",
            marginTop: "0px",
            width: "100%",
            display: "flex",
            gap: 5,
          }}
        >
          اضغط
          <div
            style={{
              color: "#014E97",
              textDecoration: "underline",
              cursor: "pointer",
            }}
            onClick={() => navigate("/profile")}
          >
            هنا
          </div>
          لتعديل الملف الشخصي
        </div>
      );
    }
  };

  const sendReq = async () => {
    setLoadingSendOffer(true);
    try {
      const { data } = await server.patch(
        `/broker-app/update-payment-request/${id}`,
        {
          account_owner_name: user.account_owner_name,
          iban: user.iban,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoadingSendOffer(false);
      reload();
      setOpen(false);
    } catch (error) {
      console.log(error);
      setLoadingSendOffer(false);
      setServerError(error.response.data.error);
    }
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleAgeeAndContinue = () => {
    sendReq();
  };

  return (
    <div>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        className="scrollbar2"
        fullWidth
        maxWidth="sm"
      >
        <DialogContent
          sx={{
            direction: "rtl",
            // display: "flex",
            flexDirection: "column",
            alignItems: "center",
            // justifyContent: "center",
            padding: "1vmax",
          }}
        >
          <div className="text8">طلب صرف</div>
          <div
            className="text3 mr0"
            style={{
              marginBottom: 5,
              width: "auto",
              marginTop: 10,
            }}
          >
            المبلغ المستحق
          </div>
          <div style={{ width: "auto", display: "flex" }}>
            <TextField
              fullWidth
              sx={{
                ".MuiOutlinedInput-root": {
                  fieldset: {
                    borderRadius: "0 4px 4px 0",
                  },
                },
              }}
              value={amount}
            />
            <div
              className="text3"
              style={{
                backgroundColor: "#014E97",
                color: "white",
                borderRadius: "4px 0 0 4px",
                padding: 10,
              }}
            >
              ريال
            </div>
          </div>
          <div
            className="text3 mr0"
            style={{
              marginBottom: 5,
              width: "auto",
              marginTop: 10,
            }}
          >
            رقم الأيبان
          </div>
          <TextField
            fullWidth
            // onChange={(e) => setIban(e.target.value)}
            value={user.iban}
          />
          <ServerErrorText value={"iban"} />
          <div
            className="text3 mr0"
            style={{
              marginBottom: 5,
              width: "auto",
              marginTop: 10,
            }}
          >
            اسم صاحب الحساب
          </div>
          <TextField
            fullWidth
            // onChange={(e) => set_account_owner_name(e.target.value)}
            value={user.account_owner_name}
          />
          <ServerErrorText value={"account_owner_name"} />
          {renderUpdateProfile()}
          {renderUpdateProfilePage()}
          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              direction: "rtl",
            }}
          >
            <LoadingButton
              disabled={!user.iban || !user.account_owner_name}
              loading={loadingSendOffer}
              variant="contained"
              size="large"
              sx={{
                marginTop: "10px",
                bgcolor: "#014E97",
              }}
              onClick={sendReq}
            >
              <div className="text3">إرسال الطلب</div>
            </LoadingButton>
          </Box>
        </DialogContent>
      </BootstrapDialog>
    </div>
  );
}
