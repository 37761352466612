import { Check, RestaurantMenu } from "@mui/icons-material";
import { Box, Button } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useState } from "react";
import server from "../../api/server";
import LoadingButton from "@mui/lab/LoadingButton";
import { useNavigate } from "react-router-dom";

const ActiveApplications = ({ data, reLoad }) => {
	const token = localStorage.getItem("token");
	const [loadingAccept, setLoadingAccept] = useState(false);
	const [loadingReject, setLoadingReject] = useState(false);

	const navigate = useNavigate();

	const accept = async (number, row) => {
		setLoadingAccept(true);
		try {
			const { data } = await server.post(
				"/broker-app/accept-applications",
				{
					serial_number: number,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			reLoad();
			setLoadingAccept(false);
			navigate("/req/1");
			localStorage.setItem("req-id", number);
			localStorage.setItem("req", JSON.stringify(row));
		} catch (error) {
			console.log(error);
			setLoadingAccept(false);
		}
	};

	const reject = async (number) => {
		setLoadingReject(true);
		try {
			const { data } = await server.post(
				"/broker-app/reject-applications",
				{
					serial_number: number,
				},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			reLoad();
			setLoadingReject(false);
		} catch (error) {
			console.log(error);
			setLoadingReject(false);
		}
	};

	const renderData = () => {
		return data.slice(0, 2).map((item) => {
			if (item.broker_id) return;
			return (
				<Grid item xs={12} md={6}>
					<Box sx={boxStyle2}>
						<div className="" style={{ width: "100%" }}>
							<div
								className="text8 center"
								style={{ marginRight: "0%" }}
							>
								طلب وارد
							</div>
						</div>
						<div className="" style={{ width: "100%" }}>
							<div
								className="text23 center"
								style={{
									marginRight: "0%",
									marginTop: 10,
								}}
							>
								طلب جديد وارد بالرقم :
							</div>
						</div>
						<Box sx={boxStyle1}>
							{/* <div>
								<div className="text3">الإسم</div>
								<div className="text8">{item.owner.name}</div>
							</div> */}
							<div>
								{/* <div className="text3">رقم الجوال</div> */}
								<div className="text8">
									{item.serial_number}
								</div>
							</div>
						</Box>

						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: 10,
							}}
						>
							<LoadingButton
								loading={loadingAccept}
								variant="contained"
								sx={{ bgcolor: "#27AE60" }}
								onClick={() => accept(item.serial_number, item)}
							>
								<Check />
								<div className="text3">قبول</div>
							</LoadingButton>
							<div style={{ display: "flex" }}>
								<div className="text8">أو</div>
								<LoadingButton
									loading={loadingReject}
									variant="contained"
									color="error"
									sx={{ mr: "10px" }}
									onClick={() => reject(item.serial_number)}
								>
									<div className="text3">رفض</div>
								</LoadingButton>
							</div>
						</div>
					</Box>
				</Grid>
			);
		});
	};
	return (
		<Grid
			container
			spacing={1}
			direction="row"
			justifyContent="flex-start"
			alignItems="flex-start"
			alignContent="stretch"
			wrap="wrap"
		>
			{renderData()}
		</Grid>
	);
};

export default ActiveApplications;

const boxStyle2 = {
	direction: "rtl",
	border: "1px solid var(--form-stroke, #E7E7E7)",
	padding: "15px",
	borderRadius: "8px",
	marginTop: 1,
	width: "auto",
	backgroundColor: "#fff",
};

const boxStyle1 = {
	direction: "rtl",
	border: "1px solid var(--form-stroke, #E7E7E7)",
	padding: "10px",
	borderRadius: "8px",
	marginTop: 1,
	width: "auto",
	backgroundColor: "#F4F7FF",
	display: "flex",
	justifyContent: "space-between",
};
