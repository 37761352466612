import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Selector from "../custom/Selector";
import HorizontalLinearStepper from "./Stepper";
import LoadingButton from "@mui/lab/LoadingButton";
import { Check, Close } from "@mui/icons-material";
import server from "../../api/server";
import ConfirmModal from "../custom/ConfirmModal";
import { useReqStore } from "../../store";
import { redirect } from "../custom/common";
import { NumericFormat } from "react-number-format";

const boxStyle1 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "10px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#F4F7FF",
  display: "flex",
  justifyContent: "space-between",
};

const boxStyle2 = {
  direction: "rtl",
  border: "1px solid var(--form-stroke, #E7E7E7)",
  padding: "15px",
  borderRadius: "8px",
  marginTop: 1,
  width: "auto",
  backgroundColor: "#fff",
};

const ReqStage1 = () => {
  const { req, setReq } = useReqStore();
  const reqId = localStorage.getItem("req-id");
  const token = localStorage.getItem("token");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loadingRepresenter, setLoadingRepresenter] = useState(true);
  const [representer, setRepresenter] = useState({});
  const [loadingSettings, setLoadingSettings] = useState(true);
  const [settings, setSettings] = useState({});
  const [openConfirm, setOpenConfirm] = useState(false);
  const tax = req.tax_origin;
  const [iban, setIban] = useState("");
  const [account_owner_name, setAccount_owner_name] = useState("");
  const [loadingSave, setLoadingSave] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  //data to be sent
  const [propertyUse, setPropertyUse] = useState(1);
  const [docType, setDocType] = useState(1);
  const [propertyType, setPropertyType] = useState(1);
  const [contract_period, set_contract_period] = useState("");
  const [iban_no, set_iban_no] = useState("");
  const [national_address, set_national_address] = useState("");

  //deed
  const [deed, setDeed] = useState(null);
  const [deedNo, setDeedNo] = useState("");
  const [successDeed, setSuccessDeed] = useState(false);
  const [failedDeed, setFailedDeed] = useState(false);
  const [loadingDeed, setLoadingDeed] = useState(false);

  const getData = async () => {
    try {
      const { data } = await server.get(
        `/broker-app/get-application-details/${reqId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setReq(data.data);
      redirect(data.data.contracts?.status_id, undefined, navigate);
      setLoadingPage(false);
    } catch (error) {
      console.log(error);
    }
  };
  const getDeed = async () => {
    if (deedNo === "") return;
    setLoadingDeed(true);
    setSuccessDeed(false);
    setFailedDeed(false);
    try {
      const { data } = await server.get(
        `/broker-app/get-deed-details/${deedNo}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setDeed(data.data);
      setLoadingDeed(false);
      setSuccessDeed(true);
    } catch (error) {
      console.log(error);

      setLoadingDeed(false);
      setDeed(null);
      setFailedDeed(true);
      // setError(Object.keys(error.response.data.error).toString());
    }
  };

  //vaildations
  const [errorList, setErrorList] = useState({});

  const ErrorText = (field) => {
    if (errorList[field]) {
      return (
        <div
          className="text2"
          style={{ color: "red", marginTop: "0px", width: "100%" }}
        >
          {errorList[field][0]}
        </div>
      );
    }
  };

  const getRepresenter = async () => {
    try {
      const { data } = await server.get(`/broker-app/get-legal-representer`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setRepresenter(data.data);
      setLoadingRepresenter(false);
    } catch (error) {
      console.log(error);
    }
  };

  const getSettings = async () => {
    try {
      const { data } = await server.get(`/broker-app/settings`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setSettings(data.data);
      setLoadingSettings(false);
    } catch (error) {
      console.log(error);
    }
  };

  const sendContract = async () => {
    setLoading(true);
    try {
      const { data } = await server.post(
        "/broker-app/store-contract",
        {
          application_id: req.id,
          deedNumber: req.deed_no,
          contract_period: "12",
          legal_representer_id: representer.user_id,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      localStorage.setItem("req", JSON.stringify(data.data));

      navigate("/req/2");
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const saveIban = async () => {
    setLoadingSave(true);
    try {
      const { data } = await server.post(
        `/broker-app/create-bank-info`,
        {
          customer_id: req.owner.id,
          iban,
          account_owner_name,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setLoadingSave(false);
      getData();
    } catch (error) {
      setLoadingSave(false);
      if (error.response?.status === 422) {
        setErrorList(error.response.data.error);
      }
      console.log(error);
    }
  };

  const handleNext = () => {
    setErrorList({});
    setOpenConfirm(true);
  };

  useEffect(() => {
    getData();
    getSettings();
    getRepresenter();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <ConfirmModal
        open={openConfirm}
        setOpen={setOpenConfirm}
        confirm={sendContract}
      />
      <div
        className="box10"
        style={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
        }}
      >
        <div className="box11" style={{ width: "100%" }}>
          <div className="text8 center" style={{ marginRight: "0%" }}>
            طلب وارد
          </div>
        </div>
      </div>
      <Box
        sx={{
          direction: "rtl",
          border: "1px solid var(--form-stroke, #E7E7E7)",
          padding: "10px",
          borderRadius: "8px",
          marginTop: 1,
          width: "auto",
          backgroundColor: "#fff",
        }}
      >
        <HorizontalLinearStepper acitve={0} />
      </Box>
      {loadingPage ? (
        <div className="lading">
          <CircularProgress />
        </div>
      ) : (
        <Grid
          container
          spacing={1}
          direction="row-reverse"
          justifyContent="flex-start"
          alignItems="flex-start"
          alignContent="stretch"
          wrap="wrap"
        >
          <Grid item xs={12} md={7}>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }}>
                <div className="text8 center" style={{ marginRight: "0%" }}>
                  بيانات المالك
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                  spacing={0}
                >
                  <Grid item xs={8}>
                    <div className="text3">الاسم</div>
                    <div className="text8">{req.owner.name}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text3">رقم الجوال</div>
                    <div className="text8">{req.owner.phone}</div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={boxStyle1}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                  spacing={1}
                >
                  <Grid item xs={8}>
                    <div className="text3">رقم الهوية</div>
                    <div className="text8">
                      {req.owner.identity_number || "لايوجد"}
                    </div>
                  </Grid>
                  {req.owner.iban ? (
                    <Grid item xs={4}>
                      <div className="text3">رقم الايبان</div>
                      <div className="text8">{req.owner.iban || "لايوجد"}</div>
                    </Grid>
                  ) : (
                    <>
                      <Grid item xs={8}>
                        <Box>
                          <div className="text3">رقم الايبان</div>
                          <TextField
                            fullWidth
                            name="iban"
                            error={errorList.iban}
                            onChange={(e) => setIban(e.target.value)}
                            sx={{ mt: 0.5 }}
                          />
                          {ErrorText("iban")}
                        </Box>
                      </Grid>
                      <Grid item xs={4}>
                        <Box>
                          <div className="text3">اسم صاحب الحساب</div>
                          <TextField
                            name="account_owner_name"
                            error={errorList.account_owner_name}
                            onChange={(e) =>
                              setAccount_owner_name(e.target.value)
                            }
                            sx={{ mt: 0.5 }}
                          />
                          {ErrorText("account_owner_name")}
                        </Box>
                      </Grid>
                    </>
                  )}
                </Grid>
              </Box>
              {!req.owner.iban && (
                <Grid
                  item
                  xs={12}
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    mt: 2,
                  }}
                >
                  <LoadingButton
                    loading={loadingSave}
                    variant="contained"
                    sx={{
                      backgroundColor: "#004A98",
                    }}
                    onClick={saveIban}
                  >
                    <div className="text3">حفظ</div>
                  </LoadingButton>
                </Grid>
              )}
              <div className="" style={{ width: "100%", marginTop: 10 }}>
                <div className="text8 center" style={{ marginRight: "0%" }}>
                  بيانات الصك
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <div className="text3">رقم الصك</div>
                    <div className="text8">{req.deed_no || "لا يوجد"}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text3">اسم مالك العقار</div>
                    <div className="text8">
                      {req.property_owner_name || "لايوجد"}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text3">المدينة</div>
                    <div className="text8">
                      {req.property_location || "لا يوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={boxStyle1}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <div className="text3">الرمز البريدي</div>
                    <div className="text8">{req.short_code || "لا يوجد"}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text3">الحي</div>
                    <div className="text8">
                      {req.property_neighbourhood || "لا يوجد"}
                    </div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text3">رقم المبنى</div>
                    <div className="text8">{req.property_no || "لا يوجد"}</div>
                  </Grid>
                </Grid>
              </Box>
              <div className="" style={{ width: "100%" }}>
                <div
                  className="text8 center"
                  style={{ marginRight: "0%", marginTop: "10px" }}
                >
                  العقار
                </div>
              </div>
              <Box sx={boxStyle1}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                  spacing={0}
                >
                  {/* <Grid item xs={8}>
                    <div className="text3">استخدام العقار</div>
                    <div className="text8">{req.building_use?.name}</div>
                  </Grid> */}
                  <Grid item xs={8}>
                    <div className="text3">نوع الوثيقة</div>
                    <div className="text8">{"وثيقة ملكية عقارية"}</div>
                  </Grid>
                  <Grid item xs={4}>
                    <div className="text3">نوع العقار</div>
                    <div className="text8">
                      {req.building_type?.name || "لايوجد"}
                    </div>
                  </Grid>
                </Grid>
              </Box>
              <Box sx={boxStyle1}>
                <Grid
                  container
                  justifyContent="flex-start"
                  alignItems="flex-start"
                  alignContent="stretch"
                  wrap="wrap"
                  spacing={0}
                >
                  <Grid item xs={4}>
                    <div className="text3">مدة العقد</div>
                    <div className="text8">{"12 شهر"}</div>
                  </Grid>
                </Grid>
              </Box>
            </Box>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }}>
                <div className="text8 center" style={{ marginRight: "0%" }}>
                  الطرف الثاني
                </div>
              </div>
              <div className="" style={{ width: "100%" }}>
                <div
                  className="text23 center"
                  style={{
                    marginRight: "0%",
                    marginTop: 10,
                  }}
                >
                  بيانات الجهة
                </div>
              </div>
              {loadingSettings ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className="text3">اسم المؤسسة / المنشأة</div>
                        <div className="text8">
                          {settings.platformName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">رقم الجوال</div>
                        <div className="text8">{settings.phone}</div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className="text3">رقم السجل</div>
                        <div className="text8">{settings.license_no}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">تاريخ الانتهاء</div>
                        <div className="text8">
                          {settings.license_expired_date}
                        </div>
                      </Grid>
                      {/* <Grid item xs={4}>
											<div className="text3">
												العنوان الوطني
											</div>
											<div className="text8">
												54324421
											</div>
										</Grid> */}
                    </Grid>
                  </Box>
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23 center"
                      style={{
                        marginRight: "0%",
                        marginTop: 10,
                      }}
                    >
                      العنوان الوطني
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <div className="text3">المدينة</div>
                        <div className="text8">{settings.cityName}</div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الرمز البريدي</div>
                        <div className="text8">{settings.districtCode}</div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الحي</div>
                        <div className="text8">{settings.districtName}</div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">رقم المبنى</div>
                        <div className="text8">{settings.landNumber}</div>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}

              {/*  */}
              {loadingRepresenter ? (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23"
                      style={{
                        marginRight: "0%",
                        marginTop: "10px",
                      }}
                    >
                      بيانات ممثل الجهة
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className="text3">الاسم</div>
                        <div className="text8">{representer.user?.name}</div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">رقم الجوال</div>
                        <div className="text8">{representer.user?.phone}</div>
                      </Grid>
                    </Grid>
                  </Box>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={8}>
                        <div className="text3">السجل المدني</div>
                        <div className="text8">
                          {representer?.user?.identity_number || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={4}>
                        <div className="text3">العنوان الوطني</div>
                        <div className="text8">
                          {representer?.user?.national_address || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                  <div className="" style={{ width: "100%" }}>
                    <div
                      className="text23 center"
                      style={{
                        marginRight: "0%",
                        marginTop: 10,
                      }}
                    >
                      العنوان الوطني
                    </div>
                  </div>
                  <Box sx={boxStyle1}>
                    <Grid container spacing={0}>
                      <Grid item xs={3}>
                        <div className="text3">المدينة</div>
                        <div className="text8">
                          {representer.user?.cityName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الرمز البريدي</div>
                        <div className="text8">
                          {representer.user?.districtCode || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">الحي</div>
                        <div className="text8">
                          {representer.user?.districtName || "لا يوجد"}
                        </div>
                      </Grid>
                      <Grid item xs={3}>
                        <div className="text3">رقم المبنى</div>
                        <div className="text8">
                          {representer.user?.landNumber || "لا يوجد"}
                        </div>
                      </Grid>
                    </Grid>
                  </Box>
                </>
              )}
            </Box>
          </Grid>
          <Grid item xs={12} md={5}>
            <Box sx={boxStyle2}>
              <div className="" style={{ width: "100%" }}>
                <div className="text8 center" style={{ marginRight: "0%" }}>
                  تفاصيل الطلب
                </div>
              </div>
              <Box sx={boxStyle2}>
                <div className="text3">مبلغ الإيجار</div>
                <div
                  className="text6"
                  style={{
                    fontSize: "1.5rem",
                    color: "#014E97",
                    display: "flex",
                  }}
                >
                  <NumericFormat
                    displayType="text"
                    thousandSeparator=","
                    value={req.origin_rent_amount}
                  />
                  <div style={{ marginRight: 5 }}>ريال</div>
                </div>
              </Box>
              <Grid
                container
                spacing={1}
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                alignContent="stretch"
                wrap="wrap"
              >
                <Grid item xs={12} md={6}>
                  <Box sx={boxStyle2}>
                    <div className="text3">الضريبة المتفق عليها</div>
                    <div
                      style={{
                        display: "flex",
                        marginTop: 5,
                        gap: 5,
                      }}
                    >
                      {/* <div className="text3">15%</div> */}
                      <div className="text8" style={{ color: "#014E97" }}>
                        <NumericFormat
                          displayType="text"
                          thousandSeparator=","
                          value={tax}
                        />
                        <div style={{ marginRight: 5 }}>ريال</div>
                      </div>
                    </div>
                  </Box>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Box sx={boxStyle2}>
                    <div className="text3">المجموع</div>
                    <div
                      className="text8"
                      style={{
                        color: "#014E97",
                        marginTop: 5,
                      }}
                    >
                      <NumericFormat
                        displayType="text"
                        thousandSeparator=","
                        value={req.total_origin_amount}
                      />
                      <div style={{ marginRight: 5 }}>ريال</div>
                    </div>
                  </Box>
                </Grid>
              </Grid>
              <LoadingButton
                loading={loading}
                variant="contained"
                sx={{
                  backgroundColor: "#004A98",
                  width: "100%",
                  mt: 1,
                }}
                onClick={handleNext}
                disabled={!req.owner.iban}
              >
                <div className="text3">التالي</div>
              </LoadingButton>
            </Box>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default ReqStage1;
