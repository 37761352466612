import {
	CallMissedOutgoing,
	CreditCardOff,
	CreditScore,
} from "@mui/icons-material";
import { Box, Button, CircularProgress } from "@mui/material";
import Grid from "@mui/material/Grid";
import { useEffect, useState } from "react";
import server from "../../api/server";
import CashOut from "./CashOut";
import CustomPaginationActionsTable from "./DataGrid";

const Wallet = () => {
	const token = localStorage.getItem("token");
	const [data, setData] = useState([]);
	const [openCashOut, setOpenCashOut] = useState(false);
	const [loading, setLoading] = useState(true);
	const [collected, setCollected] = useState(0);
	const [uncollected, setUncollected] = useState(0);
	const [rejected, setRejected] = useState(0);

	const calculateAmount = (arr) => {
		arr.map((item) => {
			if (item.payment_status_id === 3) {
				setCollected(item.total_amount);
			} else if (item.payment_status_id === 4) {
				setUncollected(item.total_amount);
			}
		});
	};

	const getData = async () => {
		try {
			const { data } = await server.get(
				`/broker-app/get-wallet-details`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setData(data.data.wallet.payment_requests);
			setRejected(data.data.rejected_applications);
			calculateAmount(data.data.statusCounts);
			setLoading(false);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		getData();
	}, []);

	return (
		<div style={{ width: "100%" }}>
			{/* <CashOut open={openCashOut} setOpen={setOpenCashOut} /> */}
			{loading ? (
				<div style={{ width: "100%", textAlign: "center" }}>
					<CircularProgress />
				</div>
			) : (
				<>
					<div
						className="box10"
						style={{
							display: "flex",
							justifyContent: "start",
							width: "100%",
						}}
					>
						<div className="box11" style={{ width: "100%" }}>
							<div
								className="text8 center"
								style={{ marginRight: "0%" }}
							>
								المحفظة
							</div>
						</div>
					</div>
					<Grid
						container
						spacing={2}
						direction="row-reverse"
						justifyContent="flex-start"
						alignItems="flex-start"
						alignContent="stretch"
						wrap="wrap"
					>
						<Grid item xs={12} md={4}>
							<div
								className=""
								style={{
									alignItems: "flex-start",
									padding: 10,
									background:
										"linear-gradient(276deg, #003C5D 9.05%, rgba(2, 163, 105, 0.71) 146.29%)",
									direction: "rtl",
									borderRadius: "8px",
								}}
							>
								<div
									style={{
										display: "flex",
										width: "100%",
										marginRight: "10px",
										marginTop: 10,
									}}
								>
									<CreditScore sx={{ color: "#fff" }} />
									<div
										className="text8"
										style={{
											marginRight: 5,
											color: "#fff",
										}}
									>
										المحصل
									</div>
								</div>
								<div
									className="border"
									style={{ marginBlock: 10, color: "#fff" }}
								></div>
								<div
									className="text22"
									style={{ fontSize: "2rem", color: "#fff" }}
								>
									{collected}
								</div>
								<div
									className="text22"
									style={{ color: "#fff" }}
								>
									ريال
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div
								className=""
								style={{
									alignItems: "flex-start",
									padding: 10,
									background:
										"linear-gradient(276deg, #2F005D 9.05%, rgba(204, 0, 0, 0.71) 146.29%)",
									direction: "rtl",
									borderRadius: "8px",
								}}
							>
								<div
									style={{
										display: "flex",
										width: "100%",
										marginRight: "10px",
										marginTop: 10,
									}}
								>
									<CreditCardOff sx={{ color: "#fff" }} />
									<div
										className="text8"
										style={{
											marginRight: 5,
											color: "#fff",
										}}
									>
										غير المحصل
									</div>
								</div>
								<div
									className="border"
									style={{ marginBlock: 10, color: "#fff" }}
								></div>
								<div
									className="text22"
									style={{ fontSize: "2rem", color: "#fff" }}
								>
									{uncollected}
								</div>
								<div
									className="text22"
									style={{ color: "#fff" }}
								>
									ريال
								</div>
							</div>
						</Grid>
						<Grid item xs={12} md={4}>
							<div
								className=""
								style={{
									alignItems: "flex-start",
									padding: 10,
									background:
										"linear-gradient(90deg, #536976 0%, #292E49 100%)",
									direction: "rtl",
									borderRadius: "8px",
								}}
							>
								<div
									style={{
										display: "flex",
										width: "100%",
										marginRight: "10px",
										marginTop: 10,
									}}
								>
									<CallMissedOutgoing
										sx={{ color: "#fff" }}
									/>
									<div
										className="text8"
										style={{
											marginRight: 5,
											color: "#fff",
										}}
									>
										الفرص الفائتة
									</div>
								</div>
								<div
									className="border"
									style={{ marginBlock: 10, color: "#fff" }}
								></div>
								<div
									className="text22"
									style={{ fontSize: "2rem", color: "#fff" }}
								>
									{rejected}
								</div>
								<div
									className="text22"
									style={{ color: "#fff" }}
								>
									فرص
								</div>
							</div>
						</Grid>
					</Grid>
					<Box
						sx={{
							direction: "rtl",
							border: "1px solid var(--form-stroke, #E7E7E7)",
							padding: "15px",
							borderRadius: "8px",
							marginTop: 2,
							width: "auto",
							backgroundColor: "#fff",
						}}
					>
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								marginTop: 5,
							}}
						>
							<div className="text8">طلب صرف</div>
							{/* <Button
								variant="contained"
								sx={{ backgroundColor: "#004A98" }}
								onClick={() => setOpenCashOut(true)}
							>
								<div className="text3">إنشاء طلب صرف</div>
							</Button> */}
						</div>
						<div
							style={{
								backgroundColor: "#F4F7FF",
								padding: 10,
								borderRadius: 8,
								width: "auto",
								textAlign: "center",
								marginTop: 10,
							}}
						>
							{data.length !== 0 ? (
								<CustomPaginationActionsTable
									rows={data}
									noPagination
									reload={getData}
								/>
							) : (
								<div
									className="text8"
									style={{
										width: "100%",
										textAlign: "center",
										display: "flex",
										justifyContent: "center",
									}}
								>
									لاتوجد طلبات صرف
								</div>
							)}
						</div>
					</Box>
				</>
			)}
		</div>
	);
};

export default Wallet;
