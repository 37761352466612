import {
  Button,
  Checkbox,
  Grid,
  TextField,
  IconButton,
  styled,
  CircularProgress,
  Snackbar,
  Alert,
  Box,
} from "@mui/material";
import { useEffect, useState } from "react";
import server from "../../api/server";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LoadingButton } from "@mui/lab";
import icon1 from "../../assets/upload.png";
import "./profile.css";
import dayjs from "dayjs";

// const user = JSON.parse(localStorage.getItem("user"));
const Profile = () => {
  const [user, setUser] = useState({});
  const [profile_pic, setProfile_pic] = useState(null);
  const [Loading, setLoading] = useState(false);
  const [LoadingPage, setLoadingPage] = useState(true);
  const [lat, setLat] = useState(user.lat);
  const [long, setLong] = useState(user.long);
  const [borkerTypes, setBorkerTypes] = useState([]);
  const [loadingBrokerTypes, setLoadingBrokerTypes] = useState(true);
  const [address, setAddress] = useState(user.address);

  const [feedBack, setFeedBack] = useState(false);

  const token = localStorage.getItem("token");

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setFeedBack(false);
  };

  const formdata = new FormData();

  const handleUpdate = async () => {
    profile_pic && formdata.append("profile_pic", profile_pic);
    user.identity_number &&
      formdata.append("identity_number", user.identity_number);
    user.broker_type_id &&
      formdata.append("broker_type_id", user.broker_type_id);
    formdata.append("email", user.email);
    user.dob && formdata.append("dob", dayjs(user.dob));
    formdata.append("long", user.long);
    formdata.append("lat", user.lat);
    formdata.append("phone", user.phone);
    formdata.append("name", user.name);
    formdata.append("fal_license", user.fal_license);
    formdata.append("fal_license_expiration", user.fal_license_expiration);
    formdata.append("iban", user.iban);
    formdata.append("account_owner_name", user.account_owner_name);
    user.cr_end_date &&
      formdata.append(
        "cr_end_date",
        dayjs(user.cr_end_date).format("YYYY/MM/DD")
      );
    user.cr_number && formdata.append("cr_number", user.cr_number);
    setLoading(true);
    try {
      const { data } = await server.post(
        "/broker-app/update-profile",

        formdata,

        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      console.log(data);
      // setUserData(data.data);
      setUser(data.data);
      localStorage.setItem("user", JSON.stringify(data.data));
      setFeedBack(true);
      setLoading(false);
      setTimeout(() => {
        window.location.reload();
      }, [4000]);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  const getUser = async () => {
    try {
      const { data } = await server.get("/broker-app/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoadingPage(false);
      setUser(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAddrss = async () => {
    const link = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${lat}&lon=${long}`;
    fetch(link)
      .then((res) => res.json())
      .then((data) => setAddress(data.address))
      .then(console.log(address));
  };

  const getBrokerIdTypes = async () => {
    try {
      const { data } = await server.get("/broker-app/get-types", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setBorkerTypes(data.data);
      setLoadingBrokerTypes(false);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      setUser((prev) => ({
        ...prev,
        lat: position.coords.latitude,
        long: position.coords.longitude,
      }));
      // console.log(lat);
      getAddrss();
    });
    getUser();
    getBrokerIdTypes();
  }, []);

  // useEffect(() => {
  // 	if (long) {
  // 	}
  // }, [long]);

  const handleChangeBrokerType = (id) => {
    setUser((prev) => ({ ...prev, broker_type_id: id }));
  };
  const handlecheckNationalId = () => {
    setUser((prev) => ({ ...prev, broker_type_id: 1 }));
  };

  const handleCheckSpecialResidency = () => {
    setUser((prev) => ({ ...prev, broker_type_id: 2 }));
  };

  const handleCheckResidencyId = () => {
    setUser((prev) => ({ ...prev, broker_type_id: 3 }));
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const renderBrokerTypes = () => {
    return borkerTypes.map((type) => {
      return (
        <Grid item md={4} xs={12} key={type.id}>
          <div
            className={
              user.broker_type_id === type.id ? "check-box2" : "check-box1"
            }
            onClick={() => handleChangeBrokerType(type.id)}
          >
            <div className="text5">{type.type_name}</div>
            <Checkbox
              style={{ transform: "scale(0.7)" }}
              onClick={() => handleChangeBrokerType(type.id)}
              checked={user.broker_type_id === type.id}
            />
          </div>
        </Grid>
      );
    });
  };

  return (
    <div style={{ width: "100%" }}>
      <Snackbar open={feedBack} autoHideDuration={5000} onClose={handleClose}>
        <Alert onClose={handleClose} severity="success" sx={{ width: "100%" }}>
          <div className="text3">تم التعديل بنجاح</div>
        </Alert>
      </Snackbar>
      {LoadingPage ? (
        <div style={{ width: "100%", textAlign: "center" }}>
          <CircularProgress />
        </div>
      ) : (
        <Grid container direction={"row-reverse"} spacing={2}>
          <Grid item lg={4} md={4} sm={12} xs={12}>
            <div
              className="box1 logIn"
              style={{ height: "auto", gap: "0.1vmax" }}
            >
              <div className="pro-box">
                <div className="text10 text-r mr0">الصورة الشخصية</div>
                <img
                  alt="profile"
                  src={`https://api.qistar.sa/storage/${user.profile_pic}`}
                  className="pro-img"
                />
              </div>
              <div style={{ width: "100%" }} />
              <div className="box12" style={{ height: "auto" }}>
                <IconButton component="label">
                  <VisuallyHiddenInput
                    type="file"
                    onChange={(e) => setProfile_pic(e.target.files[0])}
                  />
                  <div className="box13">
                    <img src={icon1} alt="icon" />
                  </div>
                </IconButton>
                {profile_pic ? (
                  <div className="text2 t201 bold">{profile_pic.name}</div>
                ) : (
                  <div className="text2 t201 bold">ارفاق صورة شخصية</div>
                )}
              </div>
              {/* {showErrorText("financial_statements")} */}
            </div>
          </Grid>
          <Grid item lg={8} md={8} sm={12} xs={12}>
            <div className="logIn" style={{ width: "90%", marginTop: "1rem" }}>
              <div className="text1">المعلومات الشخصية</div>
              <Grid
                container
                spacing={3}
                sx={{
                  display: "flex",
                  // justifyContent: "center",
                  alignItems: "center",
                }}
              >
                {loadingBrokerTypes ? (
                  <Box sx={{ width: "100%", textAlign: "center" }}>
                    <CircularProgress />
                  </Box>
                ) : (
                  renderBrokerTypes()
                )}
              </Grid>
              <Grid container spacing={2} direction={"row-reverse"}>
                {(user.broker_type_id === 3 || user.broker_type_id === 2) && (
                  <>
                    <Grid item xs={12} lg={6}>
                      <div className="text2">رقم السجل</div>
                      <TextField
                        sx={{
                          fontFamily: "font",
                          backgroundColor: "#F1F4FF",
                          width: "100%",
                          direction: "rtl",
                          color: "GrayText",
                          // fontWeight: "700",
                          // height: "1rem",
                        }}
                        size="small"
                        defaultValue={user.cr_number}
                        className="textInput1"
                        variant="outlined"
                        onChange={(e) =>
                          setUser((prev) => ({
                            ...prev,
                            cr_number: e.target.value,
                          }))
                        }
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <div className="text2">تاريخ انهاء السجل</div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DemoContainer components={["MobileDatePicker"]}>
                          <MobileDatePicker
                            sx={{
                              backgroundColor: "#F1F4FF",
                              width: "100%",
                              // direction: "rtl",
                              color: "GrayText",
                              fontFamily: "font",
                              padding: 0,
                            }}
                            size="small"
                            // value={dayjs(user.date)}
                            defaultValue={dayjs(user.cr_end_date)}
                            className="textInput1"
                            variant="outlined"
                            format="DD-MM-YYYY"
                            onChange={(newValue) =>
                              setUser((prev) => ({
                                ...prev,
                                cr_end_date: newValue,
                              }))
                            }
                            renderInput={
                              <TextField
                                sx={{
                                  backgroundColor: "#F1F4FF",
                                  width: "100%",
                                  direction: "rtl",
                                  color: "GrayText",
                                  // fontWeight: "700",
                                  fontFamily: "font",
                                  // height: "1rem",
                                }}
                                fullWidth
                              />
                            }
                            // disableOpenPicker
                          />
                        </DemoContainer>
                      </LocalizationProvider>
                    </Grid>
                  </>
                )}
                {user.broker_type_id === 1 && (
                  <Grid item xs={12}>
                    <div className="text2">رقم الهوية</div>
                    <TextField
                      sx={{
                        fontFamily: "font",
                        backgroundColor: "#F1F4FF",
                        width: "100%",
                        direction: "rtl",
                        color: "GrayText",
                        // fontWeight: "700",
                        // height: "1rem",
                      }}
                      size="small"
                      defaultValue={user.identity_number}
                      className="textInput1"
                      variant="outlined"
                      onChange={(e) =>
                        setUser((prev) => ({
                          ...prev,
                          identity_number: e.target.value,
                        }))
                      }
                    />
                  </Grid>
                )}
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">الإسم</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.name}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        name: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">رقم الجوال</div>
                  <TextField
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    defaultValue={user.phone}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        phone: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                {user.broker_type_id === 1 && (
                  <Grid item lg={6} md={6} sm={12} xs={12}>
                    <div className="text2">تاريخ الميلاد</div>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDatePicker"]}>
                        <MobileDatePicker
                          sx={{
                            backgroundColor: "#F1F4FF",
                            width: "100%",
                            // direction: "rtl",
                            color: "GrayText",
                            fontFamily: "font",
                            padding: 0,
                          }}
                          size="small"
                          // value={dayjs(user.date)}
                          defaultValue={dayjs(user.dob)}
                          className="textInput1"
                          variant="outlined"
                          format="DD-MM-YYYY"
                          onChange={(newValue) =>
                            setUser((prev) => ({
                              ...prev,
                              dob: newValue,
                            }))
                          }
                          renderInput={
                            <TextField
                              sx={{
                                backgroundColor: "#F1F4FF",
                                width: "100%",
                                direction: "rtl",
                                color: "GrayText",
                                // fontWeight: "700",
                                fontFamily: "font",
                                // height: "1rem",
                              }}
                              fullWidth
                            />
                          }
                          // disableOpenPicker
                        />
                      </DemoContainer>
                    </LocalizationProvider>
                  </Grid>
                )}
                <Grid item md={user.broker_type_id === 1 ? 6 : 12} xs={12}>
                  <div className="text2">البريد الإلكتروني</div>
                  <TextField
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    size="small"
                    className="textInput1"
                    defaultValue={user.email}
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        email: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">تاريخ الانتهاء</div>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={["MobileDatePicker"]}>
                      <MobileDatePicker
                        sx={{
                          backgroundColor: "#F1F4FF",
                          width: "100%",
                          // direction: "rtl",
                          color: "GrayText",
                          fontFamily: "font",
                          padding: 0,
                        }}
                        size="small"
                        // value={dayjs(user.date)}
                        defaultValue={dayjs(user.fal_license_expiration)}
                        className="textInput1"
                        variant="outlined"
                        format="DD-MM-YYYY"
                        onChange={(newValue) =>
                          setUser((prev) => ({
                            ...prev,
                            fal_license_expiration: newValue,
                          }))
                        }
                        renderInput={
                          <TextField
                            sx={{
                              backgroundColor: "#F1F4FF",
                              width: "100%",
                              direction: "rtl",
                              color: "GrayText",
                              // fontWeight: "700",
                              fontFamily: "font",
                              // height: "1rem",
                            }}
                            fullWidth
                          />
                        }
                        // disableOpenPicker
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">رقم رخصة فال</div>
                  <TextField
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    size="small"
                    className="textInput1"
                    defaultValue={user.fal_license}
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        fal_license_expiration: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2} direction={"row-reverse"}>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">رقم الايبان</div>
                  <TextField
                    sx={{
                      fontFamily: "font",
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      // height: "1rem",
                    }}
                    size="small"
                    defaultValue={user.iban}
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        iban: e.target.value,
                      }))
                    }
                  />
                </Grid>
                <Grid item lg={6} md={6} sm={12} xs={12}>
                  <div className="text2">اسم صاحب الحساب</div>
                  <TextField
                    sx={{
                      backgroundColor: "#F1F4FF",
                      width: "100%",
                      direction: "rtl",
                      color: "GrayText",
                      // fontWeight: "700",
                      fontFamily: "font",
                      // height: "1rem",
                    }}
                    defaultValue={user.account_owner_name}
                    size="small"
                    className="textInput1"
                    variant="outlined"
                    onChange={(e) =>
                      setUser((prev) => ({
                        ...prev,
                        account_owner_name: e.target.value,
                      }))
                    }
                  />
                </Grid>
              </Grid>
              <LoadingButton
                loading={Loading}
                variant="contained"
                sx={{
                  backgroundColor: "#004A98",
                  width: "100%",
                }}
                onClick={handleUpdate}
              >
                <div className="text3"> تحديث المعلومات الشخصية</div>
              </LoadingButton>
            </div>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default Profile;
