import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "./styles/old.css";
import HomeLayout from "./components/layouts/individualLayout/HomeLayout";
import LoginWithPhone from "./components/Login/LoginWithEmail";
import Otp from "./components/Login/Otp";
import Signup from "./components/Login/Signup";
import Home from "./components/home/Home";
import AllReq from "./components/requests/AllReq";
import ReqStage1 from "./components/requests/ReqStage1";
import ReqStage2 from "./components/requests/ReqStage2";
import ReqStage3 from "./components/requests/ReqStage3";
import ReqStage4 from "./components/requests/ReqStage4";
import ReqStage5 from "./components/requests/ReqStage5";
import ReqStage6 from "./components/requests/ReqStage6";
import Contracts from "./components/contracts/Contracts";
import ContractDetails from "./components/contracts/ContractDetails";
import Wallet from "./components/wallet/Wallet";
import Support from "./components/support/Support";
import Ticket from "./components/support/Ticket";
import NewTicket from "./components/support/NewTicket";
import Profile from "./components/profile/Profile";

function App() {
	return (
		<div className="App">
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<LoginWithPhone />} />
					<Route path="/otp" element={<Otp />} />
					<Route path="/signup" element={<Signup />} />
					<Route
						path="/home"
						element={<HomeLayout element={<Home />} />}
					/>
					<Route
						path="/req"
						element={<HomeLayout element={<AllReq />} />}
					/>
					<Route
						path="/req/1"
						element={<HomeLayout element={<ReqStage1 />} />}
					/>
					<Route
						path="/req/2"
						element={<HomeLayout element={<ReqStage2 />} />}
					/>
					<Route
						path="/req/3"
						element={<HomeLayout element={<ReqStage3 />} />}
					/>
					<Route
						path="/req/4"
						element={<HomeLayout element={<ReqStage4 />} />}
					/>
					<Route
						path="/req/5"
						element={<HomeLayout element={<ReqStage5 />} />}
					/>
					<Route
						path="/req/6"
						element={<HomeLayout element={<ReqStage6 />} />}
					/>
					<Route
						path="/contracts"
						element={<HomeLayout element={<Contracts />} />}
					/>
					<Route
						path="/contracts/:id"
						element={<HomeLayout element={<ContractDetails />} />}
					/>
					<Route
						path="/wallet"
						element={<HomeLayout element={<Wallet />} />}
					/>
					<Route
						path="/support"
						element={<HomeLayout element={<Support />} />}
					/>
					<Route
						path="/support/ticket"
						element={<HomeLayout element={<Ticket />} />}
					/>
					<Route
						path="/support/new"
						element={<HomeLayout element={<NewTicket />} />}
					/>
					<Route
						path="/profile"
						element={<HomeLayout element={<Profile />} />}
					/>
				</Routes>
			</BrowserRouter>
		</div>
	);
}

export default App;
