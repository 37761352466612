import React, { Component } from "react";
import { Editor } from "react-draft-wysiwyg";

export default class TextEditor extends Component {
	constructor(props) {
		super(props);
		this.state = {};
	}

	render() {
		const wrapperStyle = {
			border: "1px solid #969696",
			borderRadius: "6px",
		};
		const editorStyle = {
			height: "10rem",
			padding: "1rem",
		};

		return (
			<React.Fragment>
				<Editor
					onChange={(e) =>
						this.props.setDescription(
							e.blocks.map((block) => block.text).join(" ")
						)
					}
					wrapperStyle={wrapperStyle}
					editorStyle={editorStyle}
				/>
			</React.Fragment>
		);
	}
}
