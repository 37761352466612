import { Grid, TextField } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import fal from "../../assets/fal.png";
import { useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useUserStor } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { LocalizationProvider, MobileDatePicker } from "@mui/x-date-pickers";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Terms from "./Terms";

const Signup = () => {
	const [focused, setFocused] = useState(null);
	const [name, setName] = useState(null);
	const [email, setEmail] = useState(null);
	const [fal_license, setFalLicense] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [date, setDate] = useState(null);
	const [openTerms, setOpenTerms] = useState(false);
	const [error, setError] = useState(false);

	//validatios
	const feilds = {
		name,
		email,
		fal_license,
		date,
	};
	const [errorList, setErrorList] = useState({});
	const [usedEmail, setUsedEmail] = useState(false);
	const [doNotContinue, setDoNotContinue] = useState(false);
	const validateObject = (validationFn) => {
		setDoNotContinue(false);
		const results = {};
		for (const [key, value] of Object.entries(feilds)) {
			results[key] = validationFn(value);
		}
		setErrorList(results);
	};

	const validate = (value) => {
		if (!value) {
			setDoNotContinue(true);
			return true;
		} else {
			return false;
		}
	};

	const ErrorText = () => {
		return (
			<div
				className="text2"
				style={{ color: "red", marginTop: "0px", width: "100%" }}
			>
				لايمكنك ترك هذا الحقل فارغاً
			</div>
		);
	};

	const showUsedEmail = () => {
		return (
			usedEmail && (
				<div
					className="text2"
					style={{ color: "red", marginTop: "0px" }}
				>
					هذا البريد مستخدم
				</div>
			)
		);
	};

	const { setUserData, setToken, userData, token, otpData } = useUserStor();

	const navigate = useNavigate();

	const register = async () => {
		setError(false);
		setIsLoading(true);
		try {
			const { data } = await server.post("/broker-app/register", {
				phone: otpData.phone,
				name,
				email,
				fal_license,
				fal_license_expiration: date,
				all_info_rights: "1",
				agree_terms_conditions: "1",
				broker_type: "1",
			});
			if (data.data) {
				setUserData(data.data.customer);
				setToken(data.data.token);
				// console.log(userData);
				// console.log(token);
				localStorage.setItem("token", data.data.token);
				localStorage.setItem("user", JSON.stringify(data.data.broker));
				navigate("/home");
			}
			// navigate("/otp");
		} catch (error) {
			setIsLoading(false);
			setError(true);
			console.log(error.response.data.error);
			if (error.response.data.error.email) {
				setUsedEmail(true);
			}
		}
	};

	const handleOnfocus = () => {
		setFocused(true);
	};

	const handleOnBlur = () => {
		setFocused(false);
	};

	const handleOnclickContinue = () => {
		validateObject(validate);
		if (doNotContinue) {
			console.log(errorList);
			return;
		}
		setOpenTerms(true);
	};
	const TextLabel = ({ children }) => {
		const LabelText = useMemo(() => {
			// console.log(focused);
			return (
				<div
					className="text2"
					style={{ color: focused ? "#004A98" : "#4F4F4F" }}
				>
					{children}
				</div>
			);
		}, [focused]);
		return LabelText;
	};

	return (
		<div
			style={{
				backgroundImage: `url(${image22})`,
				backgroundSize: "cover",
				height: "100vh",
				display: "flex",
			}}
		>
			<Grid
				container
				className="login-container"
				sx={{ flexDirection: "row-reverse" }}
			>
				<Grid
					item
					xs={12}
					lg={6}
					md={6}
					sm={12}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="image-container"
				>
					<img className="image1" src={image1} alt="Qistar" />
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					md={6}
					sm={12}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="login-root"
				>
					<div className="logIn">
						<div
							style={{
								display: "flex",
								justifyContent: "space-between",
								width: "100%",
							}}
						>
							<img src={fal} className="image2" alt="FAL" />
							<img src={image2} className="image2" alt="Qistar" />
						</div>
						<div className="text1" style={{ textAlign: "right" }}>
							رخصة فال لممارسة النشاط العقاري
						</div>
						<TextLabel>الأسم</TextLabel>
						<div style={{ width: "100%" }}>
							<TextField
								sx={{
									":focus-within": {
										backgroundColor: "#F1F4FF",
									},
									width: "100%",
									direction: "rtl",
									color: "GrayText",
									// fontWeight: "700px",
								}}
								className="textInput1"
								variant="outlined"
								onFocus={handleOnfocus}
								onBlur={handleOnBlur}
								onChange={(e) => setName(e.target.value)}
							/>
							{errorList.name && <ErrorText />}
						</div>
						<TextLabel>البريد الإلكتروني</TextLabel>
						<div style={{ width: "100%" }}>
							<TextField
								sx={{
									":focus-within": {
										backgroundColor: "#F1F4FF",
									},
									width: "100%",
									direction: "rtl",
									color: "GrayText",
									// fontWeight: "700px",
								}}
								className="textInput1"
								variant="outlined"
								onFocus={handleOnfocus}
								onBlur={handleOnBlur}
								onChange={(e) => setEmail(e.target.value)}
							/>
							{showUsedEmail()}
							{errorList.email && <ErrorText />}
						</div>
						<TextLabel>رقم رخصة فال</TextLabel>
						<div style={{ width: "100%" }}>
							<TextField
								sx={{
									":focus-within": {
										backgroundColor: "#F1F4FF",
									},
									width: "100%",
									direction: "rtl",
									color: "GrayText",
									// fontWeight: "700px",
								}}
								className="textInput1"
								variant="outlined"
								onFocus={handleOnfocus}
								onBlur={handleOnBlur}
								onChange={(e) => setFalLicense(e.target.value)}
							/>
							{errorList.fal_license && <ErrorText />}
						</div>
						<div className="text2">تاريخ الانتهاء</div>
						<LocalizationProvider dateAdapter={AdapterDayjs}>
							<DemoContainer
								components={["MobileDatePicker"]}
								sx={{ width: "inherit" }}
							>
								<MobileDatePicker
									sx={{
										backgroundColor: "#F1F4FF",
										width: "100%",
										// direction: "rtl",
										color: "GrayText",
										fontFamily: "font",
										padding: 0,
									}}
									size="small"
									className="textInput1"
									variant="outlined"
									format="DD-MM-YYYY"
									onChange={(newValue) => setDate(newValue)}
								/>
							</DemoContainer>
							{errorList.date && <ErrorText />}
						</LocalizationProvider>
						{/* <TextLabel>رقم الجوال</TextLabel> */}
						{/* <TextField
							sx={{
								":focus-within": {
									backgroundColor: "#F1F4FF",
								},
								width: "100%",
								direction: "rtl",
								color: "GrayText",
								// fontWeight: "700px",
							}}
							className="textInput1"
							variant="outlined"
							onFocus={handleOnfocus}
							onBlur={handleOnBlur}
							// onChange={(e) => setPhone(e.target.value)}
							value={otpData.phone}
							disabled
						/> */}

						<LoadingButton
							loading={isLoading}
							variant="contained"
							sx={{ backgroundColor: "#004A98", width: "100%" }}
							onClick={handleOnclickContinue}
							disabled={!name || !email || !fal_license || !date}
						>
							<div className="text3"> متابعة</div>
						</LoadingButton>
					</div>
				</Grid>
			</Grid>
			<Terms
				open={openTerms}
				setOpen={setOpenTerms}
				isloading={isLoading}
				register={register}
				error={error}
			/>
		</div>
	);
};

export default Signup;
