import { Alert, Snackbar } from "@mui/material";
import { useEffect, useState } from "react";

const AlertMessage = ({ message, type, open, setOpen }) => {
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert
        onClose={handleClose}
        severity={type}
        variant="filled"
        sx={{ width: "100%", fontFamily: "font", direction: "ltr" }}
      >
        {message}
      </Alert>
    </Snackbar>
  );
};

export default AlertMessage;
