import { Grid, CircularProgress, Divider } from "@mui/material";
import { LoadingButton } from "@mui/lab";
import server from "../../api/server";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const Inbox = ({ userType }) => {
	const [loading, setLoading] = useState({});
	const [loadingData, setLoadingData] = useState(true);
	const [data, setData] = useState([]);

	const localToken = localStorage.getItem("token");
	const navigate = useNavigate();

	const getData = async () => {
		try {
			const { data } = await server.get("/broker-app/get-notifications", {
				headers: {
					Authorization: `Bearer ${localToken}`,
				},
			});
			console.log(data);
			setData(data.data);
			setLoadingData(false);
		} catch (error) {
			//remove this
			setLoadingData(false);
			console.log(error);
		}
	};

	const updateData = async () => {
		try {
			const { data } = await server.get(
				"/customers/get-notifications?update_read=1",
				{
					headers: {
						Authorization: `Bearer ${localToken}`,
					},
				}
			);
			console.log(data);
		} catch (error) {
			console.log(error);
		}
	};

	const goToApplication = async (link) => {
		setLoading({ [link]: true });
		try {
			const { data } = await server.get(`${link}`, {
				headers: {
					Authorization: `Bearer ${localToken}`,
				},
			});
			// setLoading({ [link]: false });
			localStorage.setItem("req", JSON.stringify(data.data[0]));
			localStorage.setItem("req-id", data.data[0].serial_number);
			if (data.data[0].contracts) {
				if (data.data[0].contracts.status_id > 7) {
					return;
				} else if (data.data[0].contracts.status_id === 7) {
					navigate(`/contracts/${data.data[0].contracts.serial_no}`);
				} else {
					navigate(`/req/${data.data[0].contracts.status_id}`);
				}
			}
			setLoading({ [link]: false });
		} catch (error) {
			console.log(error);
			setLoading({ [link]: false });
		}
	};

	const handleShow = (item) => {
		if (item.link) {
			goToApplication(item.link);
		}
	};

	const showDetails = (text) => {
		if (text.length > 50) {
			return text.substring(0, 50) + "...";
		} else {
			return text;
		}
	};

	useEffect(() => {
		// updateData();
		getData();
	}, []);

	const renderData = () => {
		return data?.slice(0, 4).map((item) => {
			return (
				<Grid item xs={12} key={item.id}>
					<div
						className="box3"
						style={{
							background: item.is_read
								? "#fff"
								: "rgba(39, 174, 96, 0.20)",
							// marginBottom: "10px",
							// borderBottom: "solid 0.5px #999999",
							borderRadius: "5px",
							padding: 5,
							paddingLeft: 0,
							paddingBlock: 10,
						}}
					>
						<div className="box4">
							{item.is_read ? (
								<div className="shape3"></div>
							) : (
								<div
									className="shape3"
									style={{ background: "#27AE60" }}
								></div>
							)}

							<div
								className="text14 f-normal"
								style={{ textAlign: "right", width: "100%" }}
							>
								{showDetails(item.details)}
							</div>
						</div>
						{item.link && (
							<LoadingButton
								loading={loading[item.link]}
								onClick={() => handleShow(item)}
							>
								<div className="text13">عرض</div>
							</LoadingButton>
						)}
					</div>
					<Divider sx={{ mb: "3px" }} />
				</Grid>
			);
		});
	};

	return (
		<>
			{loadingData ? (
				<CircularProgress />
			) : (
				<>
					{data?.length === 0 ? (
						<div>لاتوجد رسائل</div>
					) : (
						renderData()
					)}
				</>
			)}
		</>
	);
};

export default Inbox;
