import * as React from "react";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import LoadingButton from "@mui/lab/LoadingButton";
import { Checkbox } from "@mui/material";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
	"& .MuiDialogContent-root": {
		padding: theme.spacing(2),
	},
	"& .MuiDialogActions-root": {
		padding: theme.spacing(1),
	},
}));

export default function Terms({
	open,
	setOpen,
	req,
	token,
	register,
	isloading,
	error,
}) {
	const [loading, setLoading] = React.useState(false);
	const [agree, setAgree] = React.useState({
		agree: false,
		trueInfo: false,
	});
	const navigate = useNavigate();

	const handleAgreeTrems = (e) => {
		setAgree({ ...agree, [e.target.name]: e.target.checked });
	};

	const handleClose = () => {
		setOpen(false);
	};

	const showError = () => {
		return (
			error && (
				<div
					className="text2"
					style={{
						color: "red",
						marginTop: "0px",
						marginRight: 10,
						marginBottom: 10,
					}}
				>
					حدث خطأ، يرجى مراجعة البيانات المدخلة
				</div>
			)
		);
	};

	const agreeContract = async () => {
		setLoading(true);
		try {
			const { data } = await server.patch(
				`/customers/agree-commitment-contract/${req.id}`,
				{},
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},

					redirect: "follow",
				}
			);
			console.log(data);
			navigate("/renter/requests/5");
		} catch (error) {
			console.log(error);
			setLoading(false);
		}
	};
	const handleAgeeAndContinue = () => {
		// agreeContract();
		// navigate("/home");
		register();
	};

	return (
		<BootstrapDialog
			onClose={handleClose}
			aria-labelledby="customized-dialog-title"
			open={open}
			className="scrollbar2"
			fullWidth
			maxWidth="md"
		>
			<DialogTitle
				sx={{
					m: 0,
					p: 2,
					direction: "rtl",
					display: "flex",
				}}
				id="customized-dialog-title"
			>
				<div>
					<div className="text1" style={{ width: "100%" }}>
						أحكام وشروط الانضمام الى قسطار كوسيط
					</div>
				</div>
			</DialogTitle>

			<DialogContent dividers sx={{ direction: "rtl" }}>
				{/*  */}

				<>
					<div className="box15" style={{ display: "block" }}>
						<div
							className="text11 mr0"
							style={{
								textAlign: "right",
								display: "flex",
								gap: "1.1rem",
								flexDirection: "column",
								fontSize: "1rem",
								color: "#2f2f2f",
							}}
						>
							<div>
								ان يحمل الوسيط العقاري هوية وطنية سعودية سارية
								الصلاحية / خليجي مع بطاقة هوية وطنية / جواز سفر
								ساري المفعول
							</div>
							<div>
								<div>
									أن تحمل منشأة الوساطة العقارية سجلاً تجارياً
									ساري الصلاحية يتضمن النشاطين التجاريين
									التاليين:
								</div>
								<div
									style={{
										display: "list-item",
										marginRight: "1rem",
									}}
								>
									إدارة وتأجير العقارات المملوكة أو المؤجرة
									السكنية
								</div>
								<div
									style={{
										display: "list-item",
										marginRight: "1rem",
									}}
								>
									إدارة وتأجير العقارات المملوكة أو المؤجرة
									غير السكنية
								</div>
							</div>
							<div>
								أن يكون عنوان مقر المنشأة مسجلاً في خدمة العنوان
								الوطني المقدمة من البريد السعودي
							</div>
							<div>
								إتمام الدورة التدريبية الخاصة بتأهيل الوسطاء
								العقاريين قبل البدء بعملية إبرام العقود
								التأجيرية
							</div>
							<div>
								ان يحمل الوسيط العقاري هوية وطنية سعودية سارية
								الصلاحية / خليجي مع بطاقة هوية وطنية / جواز سفر
								ساري المفعول
							</div>
							<div>
								<div>
									ان تحمل منشأة الوساطة العقارية سجلاً تجارياً
									ساري الصلاحية يتضمن النشاطين التجاريين
									التاليين:
								</div>
								<div
									style={{
										display: "list-item",
										marginRight: "1rem",
									}}
								>
									إدارة وتأجير العقارات المملوكة أو المؤجرة
									السكنية
								</div>
								<div
									style={{
										display: "list-item",
										marginRight: "1rem",
									}}
								>
									إدارة وتأجير العقارات المملوكة أو المؤجرة
									غير السكنية
								</div>
							</div>
						</div>
					</div>
					<div
						className="text11 mr0"
						style={{
							textAlign: "right",
							display: "flex",
							flexDirection: "column",
							fontSize: "1rem",
							color: "#2f2f2f",
						}}
					>
						<div
							style={{
								display: "flex",
								gap: 0,
								marginTop: "1rem",
							}}
						>
							<Checkbox
								checked={agree.agree}
								onChange={handleAgreeTrems}
								name="agree"
							/>
							<div
								style={{
									marginTop: 6,
									color: agree.agree ? "#014E97" : "inherit",
								}}
							>
								أوافق على الشروط والأحكام
							</div>
						</div>
						<div
							style={{
								display: "flex",
								gap: 0,
								marginTop: "1rem",
							}}
						>
							<Checkbox
								checked={agree.trueInfo}
								onChange={handleAgreeTrems}
								name="trueInfo"
							/>
							<div
								style={{
									marginTop: 6,
									color: agree.trueInfo
										? "#014E97"
										: "inherit",
								}}
							>
								أقر بأن جميع المعلومات صحيحة
							</div>
						</div>
					</div>
				</>
			</DialogContent>
			<DialogActions>
				<LoadingButton
					loading={isloading}
					disabled={!agree.agree || !agree.trueInfo}
					autoFocus
					onClick={handleAgeeAndContinue}
					variant="contained"
					size="large"
					sx={{
						marginTop: "10px",
						marginLeft: "10px",
						bgcolor: "#014E97",
					}}
				>
					<div className="text3" style={{ fontSize: "0.9rem" }}>
						موافقة
					</div>
				</LoadingButton>
			</DialogActions>
			{showError()}
		</BootstrapDialog>
	);
}
