import { Button, Grid, TextField, styled, InputBase } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import "./login.css";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useUserStor } from "../../store";
import LoadingButton from "@mui/lab/LoadingButton";
import { PhoneInput } from "react-international-phone";
import "react-international-phone/style.css";

const LoginWithPhone = () => {
  const [focused, setFocused] = useState(false);
  const [loading, setloading] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState("");
  const { setOtpData } = useUserStor();
  const navigate = useNavigate();

  // const user = JSON.parse(localStorage.getItem("user"));

  const token = localStorage.getItem("token");

  //check if if the user is signedin
  const getUser = async () => {
    try {
      const { data } = await server.get("/broker-app/user", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      navigate("/home");
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    getUser();
  }, []);

  // const isSignedIn = () => {
  // 	if (user?.customer_type_id === 1) {
  // 		navigate("/home");
  // 	} else if (user?.customer_type_id === 2) {
  // 		navigate("/owner/dashboard");
  // 	} else {
  // 		return;
  // 	}
  // };

  const handleOnfocus = () => {
    setFocused(true);
  };

  const handleOnBlur = () => {
    setFocused(false);
  };

  const handleOnclickContinue = async () => {
    setloading(true);
    setIsNotValid(false);
    try {
      const res = await server.post("/broker-app/get-otp", {
        phone: phoneNumber.substring(1),
      });
      console.log(res.data);
      setOtpData(res.data.data);
      navigate("/otp");
    } catch {
      console.log("something wrong");
      setloading(false);
      setIsNotValid(true);
    }
  };
  const TextLabel = ({ children }) => {
    const LabelText = useMemo(() => {
      // console.log(focused);
      return (
        <div
          className="text2"
          style={{ color: focused ? "#004A98" : "#4F4F4F" }}
        >
          {children}
        </div>
      );
    }, [focused]);
    return LabelText;
  };

  const showErrorText = () => {
    return (
      isNotValid && (
        <div className="text2" style={{ color: "red", marginTop: "0px" }}>
          الرقم غير صحيح
        </div>
      )
    );
  };

  // useEffect(() => {
  // 	isSignedIn();
  // }, []);

  return (
    <div
      style={{
        backgroundImage: `url(${image22})`,
        backgroundSize: "cover",
        height: "100vh",
        display: "flex",
      }}
    >
      <Grid
        container
        className="login-container"
        sx={{ flexDirection: "row-reverse" }}
      >
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="image-container"
        >
          <img className="image1" src={image1} alt="Qistar" />
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          md={6}
          sm={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "100vh",
          }}
          className="login-root"
        >
          <div className="logIn">
            <img src={image2} className="image2" alt="Qistar" />
            <div className="text1">تسجيل الدخول</div>
            <TextLabel>1رقم الجوال</TextLabel>
            <PhoneInput
              inputClassName="c-input"
              defaultCountry="sa"
              value={phoneNumber}
              onChange={(phone) => setPhoneNumber(phone)}
              style={{ width: "100%" }}
            />
            {showErrorText()}
            <LoadingButton
              loading={loading}
              variant="contained"
              sx={{ backgroundColor: "#004A98", width: "100%" }}
              onClick={handleOnclickContinue}
            >
              <div className="text3"> متابعة</div>
            </LoadingButton>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default LoginWithPhone;
