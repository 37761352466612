import image1 from "../../assets/empty 1.png";

const RenderEmptyScreen = () => {
	return (
		<div
			className=""
			style={{
				width: "min-content",
				display: "flex",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
			}}
		>
			<div
				className="shape10"
				style={{
					transform: "scale(0.8)",
					width: "100px",
					height: "100px",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<img src={image1} alt="img" style={{ width: "inherit" }} />
			</div>
			<div className="text20" style={{ width: "max-content" }}>
				لا توجد طلبات محفوظه
			</div>
		</div>
	);
};

export default RenderEmptyScreen;
