import { Circle } from "@mui/icons-material";
import { Grid, Breadcrumbs, Link, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useNavStore } from "../../../store";

const BreadcrumbsNav = () => {
	const { nav } = useNavStore();
	const navigate = useNavigate();
	const handleNavigation = (e, path) => {
		e.preventDefault();
		navigate(path);
	};

	const navItems = nav.items?.map((item) => {
		return (
			<Link
				key={Math.random()}
				href={item.path}
				onClick={(e) => handleNavigation(e, item.path)}
				underline="hover"
			>
				<Typography
					noWrap
					component="div"
					sx={{
						display: { xs: "none", sm: "block" },
						fontFamily: "font",
						color: "#4F4F4F",
						fontSize: "0.75rem",
					}}
				>
					{item.text}
				</Typography>
			</Link>
		);
	});

	return (
		<div style={{ marginRight: "40px" }}>
			<Breadcrumbs separator={<Circle sx={{ fontSize: "0.5rem" }} />}>
				{navItems}
				<Typography
					noWrap
					component="div"
					sx={{
						display: { xs: "none", sm: "block" },
						fontFamily: "font",
						fontSize: "0.75rem",
						color: "#4F4F4F",
					}}
				>
					{nav.text}
				</Typography>
			</Breadcrumbs>
		</div>
	);
};

export default BreadcrumbsNav;
