import "./mobileHeader.css";
import image1 from "../../../assets/image1.svg";
import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import NotificationsIcon from "@mui/icons-material/NotificationsNone";
import { Grid } from "@mui/material";
import { useUserStor } from "../../../store";
import { useNavigate } from "react-router-dom";
import { ExpandMore } from "@mui/icons-material";
import { Button } from "@mui/material";
import MobileMenu from "./MobileMenu";

export default function MobileHeader() {
  const [owner, setOwner] = React.useState(20);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);
  const [anchorLoginAs, setAnchorLoginAs] = React.useState(null);
  const [openLoginAs, setOpenLoginAs] = React.useState(false);
  const user = JSON.parse(localStorage.getItem("user"));

  const handleClickLoginAs = (event) => {
    setAnchorLoginAs(event.currentTarget);
    setOpenLoginAs(true);
  };

  const navigate = useNavigate();

  const handleChange = (event) => {
    if (event.target.value == 1) {
      useUserStor.setState({ isOwner: 1 });
      navigate("/owner/dashboard");
    } else if (event.target.value == 0) {
      useUserStor.setState({ isOwner: 0 });
    }
    // console.log("f0 " + isOwner);
  };

  const menuId = "primary-search-account-menu";

  return (
    <Box
      sx={{
        flexGrow: 1,
        direction: "rtl",
        // bgcolor: " #004A98",
        width: "100%",
        display: { md: "none" },
        background: "linear-gradient(163deg, #004A98 2.33%, #350055 153.89%)",
        // position: "absolute",
        // top: 0,
      }}
    >
      <AppBar
        position="static"
        color="transparent"
        variant="outlined"
        style={{
          backgroundColor:
            "linear-gradient(163deg, #004A98 2.33%, #350055 153.89%)",
        }}
        // className="mobile-root"
      >
        <Toolbar>
          <img
            src={image1}
            alt="logo"
            style={{
              // maxWidth: "-webkit-fill-available",
              marginLeft: "8vw",
              marginTop: "20px",
              marginBottom: "10px",
              width: "60px",
            }}
          />

          <Box sx={{ flexGrow: 1 }} />
          <Box
            sx={{
              display: {
                xs: "flex",
                md: "flex",
                marginLeft: "20px",
              },
            }}
          >
            <Grid
              container
              direction={"column"}
              sx={{
                marginInline: "20px",
                display: "flex",
                alignItems: "center",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <div
                  className="text6"
                  style={{
                    color: "#fff",
                    fontSize: "0.65rem",
                  }}
                >
                  محمد عبد الرحمن
                </div>
              </Grid>
              {/* <Grid
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                }}
              >
                <Button>
                  <div
                    className="text7"
                    style={{
                      color: "#fff",
                      fontSize: "0.6rem",
                    }}
                  >
                    وسيط عقاري
                  </div>
                </Button>
              </Grid> */}
            </Grid>

            <IconButton
              size="small"
              edge="end"
              aria-label="account of current user"
              aria-controls={menuId}
              aria-haspopup="true"
              color="inherit"
              onClick={() => navigate("/profile")}
            >
              <img
                src={`https://api.qistar.sa/storage/${user?.profile_pic}`}
                className="pro-img"
                alt="profile"
                style={{ width: "2.5rem", height: "2.5rem" }}
              />
            </IconButton>
          </Box>
        </Toolbar>
        <div>
          <MobileMenu />
        </div>
      </AppBar>
      {/* {renderMenu} */}
    </Box>
  );
}
