import { create } from "zustand";

export const useUserStor = create((set) => ({
	uuid: null,
	setUuid: (uuid) => set((state) => ({ ...state, uuid })),

	otpData: null,
	setOtpData: (otpData) => set((state) => ({ ...state, otpData })),

	userData: null,
	setUserData: (userData) => set((state) => ({ ...state, userData })),

	adminData: null,
	setAdminData: (adminData) => set((state) => ({ ...state, adminData })),

	token: null,
	setToken: (token) => set((state) => ({ ...state, token })),

	ticket: {},
	setTicket: (ticket) => set((state) => ({ ...state, ticket })),

	dashboardData: {},
	setDashboardData: (dashboardData) =>
		set((state) => ({ ...state, dashboardData })),
}));

export const useReqStore = create((set) => ({
	req: {},
	setReq: (req) => set((state) => ({ ...state, req })),
	fees: null,
	setFees: (fees) => set((state) => ({ ...state, fees })),
}));

export const useNavStore = create((set) => ({
	nav: { text: "لوحة المعلومات" },
}));
