import { Button, Grid, ImageListTile, ListSubheader } from "@mui/material";
import image22 from "../../assets/image-22.png";
import image1 from "../../assets/image1.svg";
import image2 from "../../assets/image2.svg";
import "./login.css";
import { useEffect, useState } from "react";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useUserStor } from "../../store";

const Otp = () => {
	const [otp, setOtp] = useState("");
	const navigate = useNavigate();
	const { otpData, setUserData, setToken, setOtpData } = useUserStor();
	const [error, setError] = useState(false);

	const verify = async () => {
		setError(false);
		try {
			const { data } = await server.post("/broker-app/verify-otp", {
				...otpData,
				otp,
			});
			if (data.data.broker) {
				setUserData(data.data.broker);
				setToken(data.data.token);
				localStorage.setItem("token", data.data.token);
				localStorage.setItem("user", JSON.stringify(data.data.broker));
				navigate("/home");
			} else {
				navigate("/signup");
			}
			// console.log(data.data);
		} catch (error) {
			console.log(error.message);
			setError(true);
		}
	};

	const resendOTP = async () => {
		try {
			const res = await server.post("/customers/get-otp", {
				phone: otpData.phone,
			});
			console.log(res.data);
			setOtpData(res.data.data);
			alert(res.data.data.otp);
		} catch {
			console.log("something wrong");
		}
	};

	const showError = () => {
		return (
			error && (
				<div
					className="text2"
					style={{ color: "red", marginTop: "0px" }}
				>
					رمز التحقق غير صحيح
				</div>
			)
		);
	};

	// navigation to next page
	useEffect(() => {
		if (otp.length === 5) {
			verify();
			// console.log(otpData);
		}
	}, [otp, navigate]);

	return (
		<div
			style={{
				backgroundImage: `url(${image22})`,
				backgroundSize: "cover",
				height: "100vh",
				display: "flex",
			}}
		>
			<Grid
				container
				className="login-container"
				sx={{ flexDirection: "row-reverse" }}
			>
				<Grid
					item
					xs={12}
					lg={6}
					md={6}
					sm={12}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="image-container"
				>
					<img className="image1" src={image1} alt="Qistar" />
				</Grid>
				<Grid
					item
					xs={12}
					lg={6}
					md={6}
					sm={12}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						height: "100vh",
					}}
					className="login-root"
				>
					<div className="logIn">
						<img src={image2} className="image2" alt="Qistar" />
						<div className="text1">تأكيد رقم الجوال</div>
						<div className="text4">
							قم بإدخال رمز التحقق لتاكيد رقم الهاتف
						</div>
						<OTPInput
							value={otp}
							onChange={setOtp}
							numInputs={5}
							//   renderSeparator={<span>-</span>}
							inputStyle={{
								margin: "5px",
								borderRadius: "5px",
								maxWidth: "2.5rem",
								maxHeight: "2.5rem",
								minHeight: "2.5rem",
								border: "1px solid rgba(51, 51, 51, 0.60)",

								minWidth: "2.5rem",
							}}
							containerStyle={{
								width: "100%",
								justifyContent: "center",
							}}
							renderInput={(props) => (
								<input className="otp-input" {...props} />
							)}
						/>
						{showError()}
						<Button onClick={resendOTP}>
							<div
								className="text3"
								style={{ textDecorationLine: "underline" }}
							>
								إعادة إرسال الرمز
							</div>
						</Button>
					</div>
				</Grid>
			</Grid>
		</div>
	);
};

export default Otp;
