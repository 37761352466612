import { Grid, Button, CircularProgress } from "@mui/material";
import SearchBar from "./SearchBar";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useEffect, useState } from "react";
import CustomPaginationActionsTable from "./DataGrid";
import { useNavStore } from "../../store";
import "./requests.css";
import RenderEmptyScreen from "../custom/EmptyReq";
import ActiveApplications from "../home/ActiveApplications";

const demoData = [
	{
		id: 1,
		created_at: new Date().toLocaleDateString(),
		status_id: 1,
		serial_number: 1212123,
	},
	{
		id: 2,
		created_at: new Date().toLocaleDateString(),
		status_id: 2,
		serial_number: 1212123,
	},
	{
		id: 3,
		created_at: new Date().toLocaleDateString(),
		status_id: 3,
		serial_number: 1212123,
	},
	{
		id: 4,
		created_at: new Date().toLocaleDateString(),
		status_id: 4,
		serial_number: 1212123,
	},
];

const AllReq = () => {
	const [data, setData] = useState([]);
	const [search, setSearch] = useState(null);
	const [loading, setLoading] = useState(true);
	const [activeApplications, setActiveApplications] = useState([]);

	const token = localStorage.getItem("token");

	const getData = async () => {
		try {
			const { data } = await server.get("/broker-app/applications", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setLoading(false);
			console.log(data.data);
			setData(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	const getActiveApplications = async () => {
		try {
			const { data } = await server.get(
				"/broker-app/active-applications",
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			setActiveApplications(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	useEffect(() => {
		useNavStore.setState({
			nav: {
				text: "الطلبات الواردة",
			},
		});
		getData();
		getActiveApplications();
	}, []);

	const handleSearch = async () => {
		try {
			const { data } = await server.get(
				`/broker-app/applications?serialNumber=${search}`,
				{
					headers: {
						Authorization: `Bearer ${token}`,
					},
				}
			);
			console.log(data.data);
			setData(data.data);
		} catch (error) {
			console.log(error);
		}
	};

	return (
		<div style={{ width: "100%", direction: "rtl" }}>
			<Grid item xs={12} sx={{ direction: "rtl" }}>
				<div
					className="box10"
					style={{
						display: "flex",
						justifyContent: "start",
						width: "100%",
					}}
				>
					<div className="box11" style={{ width: "100%" }}>
						<div
							className="text8 center"
							style={{ marginRight: "0%" }}
						>
							الطلبات الواردة
						</div>
					</div>
				</div>
			</Grid>
			{activeApplications.length > 0 && (
				<ActiveApplications
					data={activeApplications}
					reLoad={getActiveApplications}
				/>
			)}
			<div
				className="box14"
				style={{
					margin: "0.5rem",
					height: "auto",
					justifyContent: "center",
				}}
			>
				<div className="box10 box50">
					{/* <div className="box11 box51" style={{ paddingRight: 0 }}>
						<SearchBar setSearch={setSearch} />
						<Button
							variant="contained"
							size="small"
							onClick={handleSearch}
							sx={{
								marginRight: "10px",
								// width: "10vw",
								bgcolor: "#014E97",
								height: "35px",
							}}
						>
							<div className="text3">بحث</div>
						</Button>
					</div> */}
				</div>
				<div
					style={{
						backgroundColor: "#F4F7FF",
						padding: 10,
						borderRadius: 8,
						width: "100%",
						textAlign: "center",
					}}
				>
					{!loading ? (
						<>
							{data.length > 0 ? (
								<CustomPaginationActionsTable
									rows={data}
									noPagination
								/>
							) : (
								<div
									style={{
										width: "100%",
										display: "flex",
										alignItems: "center",
										justifyContent: "center",
										marginBottom: 20,
									}}
								>
									<RenderEmptyScreen />
								</div>
							)}
						</>
					) : (
						<CircularProgress />
					)}
				</div>
			</div>
		</div>
	);
};

export default AllReq;
