import { Grid } from "@mui/material";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useUserStor } from "../../store";

const Ticket = () => {
	const { ticket } = useUserStor();
	const date = new Date(ticket.created_at).toLocaleDateString();

	const navigate = useNavigate();

	useEffect(() => {
		if (!ticket) {
			navigate("/support");
		}
	}, []);

	return (
		<div style={{ width: "100%", gap: "20px", display: "grid" }}>
			<div className="box10">
				<div className="box11">
					<div className="text8 center">التذكرة {ticket.id}#</div>
				</div>
			</div>
			<div className="box53">
				<Grid container>
					<Grid item xs={4}>
						<div className="box11">
							<div className="text9 center">رقم التذكرة</div>
							<div className="text9" style={{ marginTop: "5%" }}>
								{ticket.serial_no}#
							</div>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className="box11">
							<div className="text9 center">تاريخ الإنشاء</div>
							<div className="text9" style={{ marginTop: "5%" }}>
								{date}
							</div>
						</div>
					</Grid>
					<Grid item xs={4}>
						<div className="box11">
							<div className="text9 center">حالة التذكرة</div>
							<div className="text9" style={{ marginTop: "5%" }}>
								{ticket.status.name}
							</div>
						</div>
					</Grid>
				</Grid>
			</div>
			<div className="box53">
				<div className="text8">العنوان</div>
				<div className="text8 text55">{ticket.title}</div>
				<div className="text8">وصف المشكلة</div>
				<div className="box56">
					<div className="text11 text56">{ticket.description}</div>
				</div>
			</div>
		</div>
	);
};

export default Ticket;
