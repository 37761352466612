import icon1 from "../../../assets/Frame.png";
import icon3 from "../../../assets/Frame (2).png";
import inreq from "../../../assets/inreq.svg";
import contract from "../../../assets/contratctW.png";
import ArticleIcon from "@mui/icons-material/Article";
import wallet from "../../../assets/wallet.svg";
import icon4 from "../../../assets/Vector.png";
import { useNavigate } from "react-router-dom";
import { ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";

const MobileMenu = () => {
  const [open, setOpen] = useState({});
  const routes = [
    {
      page: "لوحة المعلومات",
      id: "/home",
      icon: <img src={icon1} alt="icon" width={20} />,
    },
    {
      page: "الطلبات الواردة",
      id: "/req",
      icon: <img src={inreq} alt="icon" width={20} />,
    },
    {
      page: "العقود",
      id: "/contracts",
      icon: <img src={contract} alt="icon" width={20} />,
    },
    {
      page: "المحفظة",
      id: "/wallet",

      icon: <img src={wallet} alt="icon" width={20} />,
    },
    {
      page: "المساعدة والدعم",
      id: "/support",

      icon: <img src={icon4} alt="icon" width={20} />,
    },
  ];

  let navigate = useNavigate();

  const handleClick = (id) => {
    setOpen(
      Object.keys(open).forEach((key) => {
        open[key] = false;
      })
    );
    setOpen({ ...open, [id]: !open[id] });
    navigate(id);
    console.log(open);
  };

  const sidebarItems = () => {
    return routes.map(({ id, page, icon, path }) => {
      const openList = open[id] || false;

      const active = () => {
        return openList ? "active-mobile" : "reverse-mobile";
      };

      const activeIcone = () => {
        return openList ? "active-icone-mobile" : "reverse-icone-mobile";
      };

      return (
        <div
          key={id}
          style={{
            display: "flex",
            flexDirection: "column",
            marginInline: 5,
            alignItems: "center",
            justifyContent: "center",
            minWidth: "30vw",
          }}
        >
          <ListItemButton
            className={active()}
            onClick={() => handleClick(id, path)}
            sx={{
              width: "30px",
              height: "30px",
              border: "1px solid rgba(179, 237, 255, 0.25)",
              background: "rgba(255,255, 255, 0.20)",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <div
              className={activeIcone()}
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {icon}
            </div>
          </ListItemButton>
          <ListItemText
            sx={{
              ".MuiTypography-root": {
                fontFamily: "font",
                // fontWeight: "700",
                fontSize: "0.8rem",
              },
              color: "#fff",
            }}
            className={`direction `}
            primary={page}
          />
        </div>
      );
    });
  };
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        flexDirection: "row",
        paddingBottom: "20px",
        overflowX: "auto",
      }}
    >
      {sidebarItems()}
    </div>
  );
};

export default MobileMenu;
