import { Button, Grid, CircularProgress } from "@mui/material";
import icon1 from "../../assets/Group.png";
import PieChartWithCenterLabel from "./PieChart";
import icon2 from "../../assets/icon1.png";
import icon3 from "../../assets/icon3.png";
import icon4 from "../../assets/donl.png";
import inreq from "../../assets/inreq.svg";
import outreq from "../../assets/outreq.svg";
import wallet from "../../assets/wallet.svg";
import { useNavigate } from "react-router-dom";
import server from "../../api/server";
import { useState, useEffect } from "react";
import Inbox from "../custom/Inbox";
import Activities from "../custom/Activities";
import { useNavStore } from "../../store";
import RenderEmptyScreen from "../custom/EmptyReq";
import ActiveApplications from "./ActiveApplications";
import CustomPaginationActionsTable from "../requests/DataGrid";
import ReferralCode from "./ReferalCode";

const Home = () => {
  const navigate = useNavigate();
  const [data, setData] = useState(null);
  const [appdata, setAppData] = useState(null);
  const [activeApplications, setActiveApplications] = useState([]);
  const [loadingAppChart, setLoadingAppChat] = useState(false);
  const [loading, setLoading] = useState(true);
  const [loadingApp, setLoadingApp] = useState(true);
  const [appComplete, setAppComplete] = useState(0);
  const [appPending, setAppPending] = useState(0);
  const [appCanceled, setAppCanceled] = useState(0);

  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const contractData = [
    { value: appComplete, label: "النشطه", color: "#27AE60" },
    { value: appPending, label: "قيد المعالجة", color: "#FFC300" },
  ];
  const offersData = [
    { value: 7, label: "النشطه", color: "#27AE60" },
    { value: 3, label: "قيد المعالجة", color: "#FFC300" },
  ];
  // const reqData = [
  // 	{ value: 7, label: "المكتملة", color: "#27AE60" },
  // 	{ value: 2, label: "قيد المعالجة ", color: "#FFC300" },
  // 	{ value: 1, label: "الملغي", color: "#FF1F00" },
  // ];
  // const reqData = [
  // 	{ value: appComplete, label: "المكتملة", color: "#27AE60" },
  // 	{ value: appPending, label: "قيد المعالجة ", color: "#FFC300" },
  // 	{ value: appCanceled, label: "الملغي", color: "#FF1F00" },
  // ];

  const getData = async () => {
    setAppComplete(0);
    setAppPending(0);
    setAppCanceled(0);
    try {
      const { data } = await server.get("/broker-app/get-data", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoading(false);
      setData(data.data);
      calculateAppStatistics(data.data.statusCounts);
    } catch (error) {
      console.log(error);
    }
  };

  const getActiveApplications = async () => {
    try {
      const { data } = await server.get("/broker-app/active-applications", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setActiveApplications(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAppData = async () => {
    try {
      const { data } = await server.get("/broker-app/applications", {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setLoadingApp(false);
      setAppData(data.data);
    } catch (error) {
      console.log(error);
    }
  };

  const calculateAppStatistics = (arr) => {
    arr.map((obj) => {
      if (obj.id < 7) {
        setAppPending((prev) => prev + obj.count);
      }
      if (obj.id === 7) {
        setAppComplete(obj.count);
      }
      if (obj.id == 8) {
        setAppCanceled(obj.count);
      }
    });
  };

  useEffect(() => {
    useNavStore.setState({
      nav: {
        text: "لوحة المعلومات",
      },
    });
    getData();
    getActiveApplications();
    getAppData();
    // getAppStatisticts();
  }, []);

  return (
    <div style={{ width: "100%" }}>
      <Grid
        container
        direction={"row"}
        spacing={2}
        sx={{ display: "flex", direction: "rtl", mt: "0px" }}
        style={{ marginTop: "10px" }}
        mt={"0px"}
      >
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <div style={{ fontFamily: "font" }}>{`أهلا بك , ${
            user?.name.split(" ")[0]
          } 👋`}</div>
          <ReferralCode code={`https://app.qistar.sa/${user.referral_code}`} />
        </Grid>
        <Grid item xs={12}>
          {activeApplications.length > 0 && (
            <ActiveApplications
              data={activeApplications}
              reLoad={getActiveApplications}
            />
          )}
        </Grid>
        {/* <Grid item xs={12} md={4} display={"flex"}>
					<div
						className="admin-box1"
						style={{ alignItems: "flex-start", padding: "10px" }}
					>
						<div
							style={{
								display: "flex",
								width: "100%",
								marginRight: "10px",
								marginTop: 10,
							}}
						>
							<img
								src={outreq}
								alt="icon"
								style={{
									width: "20px",
									height: "20px",
									filter: "brightness(0) saturate(100%)",
								}}
							/>
							<div className="text8" style={{ marginRight: 5 }}>
								العروض
							</div>
						</div>
						<div
							className="border"
							style={{ marginBlock: 10 }}
						></div>
						<Grid
							container
							direction={"row"}
							display={"flex"}
							sx={{ mr: 1, mt: 1 }}
						>
							<Grid item xs={5}>
								<div style={{ display: "flex" }}>
									<div className="shape1"></div>
									<p className="text9">النشطه : 7</p>
								</div>
								<div style={{ display: "flex" }}>
									<div className="shape2"></div>
									<p className="text9">قيد المعالجة : 3 </p>
								</div>
							</Grid>
							<Grid
								item
								xs={7}
								display={"flex"}
								alignItems={"center"}
								justifyContent={"center"}
							>
								<PieChartWithCenterLabel data={offersData} />
							</Grid>
						</Grid>
					</div>
				</Grid> */}
        <Grid item xs={12} md={6} display={"flex"}>
          <div
            className="admin-box1"
            style={{ alignItems: "flex-start", padding: "10px" }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                marginRight: "10px",
                marginTop: 10,
              }}
            >
              <img
                src={inreq}
                alt="icon"
                style={{
                  width: "20px",
                  height: "20px",
                  filter: "brightness(0) saturate(100%)",
                }}
              />
              <div className="text8" style={{ marginRight: 5 }}>
                العقود
              </div>
            </div>
            <div className="border" style={{ marginBlock: 10 }}></div>
            <Grid
              container
              direction={"row"}
              display={"flex"}
              sx={{ mr: 1, mt: 1 }}
            >
              {loading ? (
                <div
                  style={{
                    width: "100%",
                    textAlign: "center",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <CircularProgress />
                </div>
              ) : (
                <>
                  <Grid item xs={5}>
                    <Grid item xs={12} display={"flex"}>
                      <div className="shape1"></div>
                      <p className="text9">
                        المكتملة : {contractData[0].value}
                      </p>
                    </Grid>
                    <Grid item xs={12} display={"flex"}>
                      <div className="shape2"></div>
                      <p className="text9">
                        قيد المعالجة : {contractData[1].value}{" "}
                      </p>
                    </Grid>
                    {/* <Grid item xs={12} display={"flex"}>
											<div className="shape4"></div>
											<p className="text9">
												الملغي : {appCanceled}{" "}
											</p>
										</Grid> */}
                  </Grid>
                  <Grid
                    item
                    xs={7}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <PieChartWithCenterLabel data={contractData} />
                  </Grid>
                </>
              )}
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"} sx={{ marginLeft: "0px" }}>
          <div
            className="admin-box1"
            style={{ alignItems: "flex-start", padding: 10 }}
          >
            {loading ? (
              <div
                style={{
                  width: "100%",
                  textAlign: "center",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    marginRight: "10px",
                    marginTop: 10,
                  }}
                >
                  <img
                    src={wallet}
                    alt="icon"
                    style={{
                      width: "20px",
                      height: "20px",
                      filter: "brightness(0) saturate(100%)",
                    }}
                  />
                  <div className="text8" style={{ marginRight: 5 }}>
                    المحفظة
                  </div>
                </div>
                <div className="border" style={{ marginBlock: 10 }}></div>
                <div className="text22" style={{ fontSize: "2rem" }}>
                  {data.wallet}
                </div>
                <div className="text22">ريال</div>
              </>
            )}
          </div>
        </Grid>
      </Grid>
      <Grid
        item
        container
        direction={"row"}
        spacing={2}
        sx={{ display: "flex", direction: "rtl", mt: 1 }}
      >
        <Grid item xs={12} md={6} display={"flex"}>
          <div
            className="admin-box1"
            style={{ padding: "10px", paddingRight: 10 }}
          >
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 10,
              }}
            >
              <img
                src={icon2}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div
                className="text8"
                style={{ marginRight: 5, marginBottom: 10 }}
              >
                صندوق الوارد
              </div>
            </div>
            <Grid container>
              <Inbox />
            </Grid>
          </div>
        </Grid>
        <Grid item xs={12} md={6} display={"flex"}>
          <div className="admin-box1" style={{ padding: "10px" }}>
            <div
              style={{
                display: "flex",
                width: "100%",
                marginTop: 10,
                gap: 5,
              }}
            >
              <img
                src={icon3}
                alt="icon"
                style={{ width: "20px", height: "20px" }}
              />
              <div
                className="text8"
                style={{ marginRught: 5, marginBottom: 10 }}
              >
                الأنشطة الاخيرة
              </div>
            </div>
            <Grid container>
              <Activities data={data?.activities} loadingData={loading} />
            </Grid>
          </div>
        </Grid>
      </Grid>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <div
          className="box1"
          style={{
            height: "auto",
            minWidth: "auto",
            borderRadius: "8px",
            width: "100%",
          }}
        >
          <div
            className="box10"
            style={{
              display: "flex",
              // justifyContent: "start",
              width: "100%",
            }}
          >
            <div className="box-2">
              <div
                className="box11"
                style={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  padding: 0,
                  gap: 5,
                }}
              >
                <img
                  src={outreq}
                  style={{
                    width: "20px",
                    height: "20px",
                    filter: "brightness(0) saturate(100%)",
                  }}
                />
                <div className="text8 center mr0">الطلبات الواردة</div>
              </div>
              {/* <Button
								variant="contained"
								// size="large"
								sx={{
									marginTop: "10px",
									marginLeft: "10px",
									width: "15vw",
									bgcolor: "#014E97",
								}}
								onClick={() => navigate("/home")}
							>
								<div className="text3">إنشاء طلب جديد</div>
							</Button> */}
            </div>
          </div>
          {!loadingApp ? (
            <>
              {appdata.length > 0 ? (
                <div
                  style={{
                    backgroundColor: "#F4F7FF",
                    padding: 10,
                    borderRadius: 8,
                    width: "100%",
                    textAlign: "center",
                    marginTop: 10,
                  }}
                >
                  <CustomPaginationActionsTable rows={appdata} noPagination />
                </div>
              ) : (
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginBottom: 20,
                  }}
                >
                  <RenderEmptyScreen />
                </div>
              )}
            </>
          ) : (
            <CircularProgress />
          )}
        </div>
      </div>
    </div>
  );
};

export default Home;
