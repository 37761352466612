import Grid from "@mui/material/Grid";
import SideBar from "./Sidebar";
import StatusBar from "./StatusBar";
import { useEffect, useState } from "react";
import MobileHeader from "./MobileHeader";
import { useUserStor } from "../../../store";
import { useNavigate } from "react-router-dom";
import server from "../../../api/server";
import { CircularProgress } from "@mui/material";

const HomeLayout = ({ element }) => {
	const [drawer, setDrawer] = useState(false);
	const [toggleSidebar, setToggleSeidebar] = useState(true);
	const [loading, setLoading] = useState(true);

	const { userData, setToken, setUserData } = useUserStor();
	const navigate = useNavigate();

	const token = localStorage.getItem("token");
	// const user = localStorage.getItem("user");
	// const userParsed = JSON.parse(user);

	const toggleDrawer = (open) => {
		setDrawer(open);
		console.log(drawer);
	};

	const sidebarGrid = () => {
		return toggleSidebar ? 2.3 : 0.8;
	};

	const StatusBarGrid = () => {
		return toggleSidebar ? 9.7 : 11.2;
	};

	//check if if the user is signedin
	const getUser = async () => {
		try {
			const { data } = await server.get("/broker-app/user", {
				headers: {
					Authorization: `Bearer ${token}`,
				},
			});
			setLoading(false);
			localStorage.setItem("user", JSON.stringify(data.data));
		} catch (error) {
			console.log(error);
			localStorage.removeItem("token");
			navigate("/");
		}
	};
	useEffect(() => {
		getUser();
	}, []);

	return (
		<div
			style={{
				backgroundColor: "#F4F7FF",
			}}
		>
			<div>
				<Grid
					direction={"row-reverse"}
					container
					sx={{
						height: "100vh",
						alignItems: "flex-start",
						backgroundColor: "#F4F7FF",
					}}
				>
					{/* The side bar */}
					<Grid item xs={sidebarGrid()}>
						<SideBar
							sideBarMain="sideBar-main"
							expand={toggleSidebar}
							setExpand={setToggleSeidebar}
						/>
					</Grid>
					{/* status bar */}

					<Grid
						item
						xs={12}
						md={StatusBarGrid()}
						lg={StatusBarGrid()}
						sm={12}
						sx={{
							display: "flex",
							flexDirection: "column",
							//   justifyContent: "flex-start",

							backgroundColor: "#F4F7FF",
						}}
					>
						{loading ? (
							<div style={{ width: "100%", textAlign: "center" }}>
								<CircularProgress />
							</div>
						) : (
							<>
								<StatusBar />
								<MobileHeader />
								<div
									style={{
										backgroundColor: "#F4F7FF",
										minHeight: "-webkit-fill-available",
										minHeight: "-moz-available",
										// minHeight: "100vh",
										display: "flex",
										justifyContent: "center",
										padding: "20px",
										marginInline: "40px",
									}}
									className="mh-100vh scale root-m"
								>
									{element}
								</div>
							</>
						)}
					</Grid>
				</Grid>
			</div>
		</div>
	);
};

export default HomeLayout;
