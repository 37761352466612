import { OpenInNew } from "@mui/icons-material";
import { Button, Divider, Grid } from "@mui/material";
import React from "react";

const SubContracts = ({ data }) => {
  const renderDate = (dateString) => {
    if (!dateString) return "لا يوجد";
    return `${new Date(dateString).toLocaleDateString()}`;
  };
  const renderContracts = () => {
    return data.map((item, i) => {
      return (
        <React.Fragment key={item.id}>
          <Grid
            container
            spacing={1}
            direction="row"
            justifyContent="flex-start"
            alignItems="flex-start"
            alignContent="stretch"
            wrap="wrap"
          >
            <Grid item xs={6} md={3}>
              <div>
                <div className="text3">رقم العقد</div>
                <div className="text8">{item.serial_no}</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text3">تاريخ بداية العقد</div>
                <div className="text8">{renderDate(item.start_date)}</div>
              </div>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text3">تاريخ نهاية العقد</div>
                <div className="text8">{renderDate(item.end_date)}</div>
              </div>
            </Grid>

            <Grid item xs={6} md={3}>
              <a
                href={`https://api.qistar.sa/storage/${item.ejar_file}`}
                target="_blank"
              >
                <Button variant="contained" sx={{ bgcolor: "#004A98" }}>
                  <OpenInNew />
                  <div className="text3" style={{ marginRight: 5 }}>
                    تفاصيل العقد في إيجار
                  </div>
                </Button>
              </a>
            </Grid>
            <Grid item xs={6} md={3}>
              <div>
                <div className="text3">نوع التعاقد</div>
                <div className="text8">{item.type.name}</div>
              </div>
            </Grid>
          </Grid>
          {data.length !== i + 1 && <Divider sx={{ mt: 2, mb: 2 }} />}
        </React.Fragment>
      );
    });
  };
  return <div>{renderContracts()}</div>;
};

export default SubContracts;
